body.hidden
	overflow hidden
	.popup
		display block
.popup.show_popup
	visibility visible
	opacity 1

.popup
	position fixed
	z-index 1100
	top 0
	left 0
	width 100%
	height 100%
	background transparent
	opacity 0
	display none
	visibility hidden
	transition .5s
	.close
		width 30px
		height 30px
		position absolute
		top 30px
		right 40px
		cursor pointer
		+mw(1100px)
			top 15px
			right 20px
		+mw(480px)
			width 24px
			height 24px
			right 10px
		&:hover
			&:before, &:after
				background $color2
		&:before, &:after
			content ''
			width 100%
			height 2px
			background #fff
			position absolute
			top 14px
			left 0
			transition background .3s
			+mw(480px)
				top 11px
		&:before
			transform rotate(45deg)
		&:after
			transform rotate(135deg)
	&__content
		position absolute
		top 5vh
		left 12%
		width 76%
		height 90vh
		max-width 100%
		min-height 320px
		overflow hidden
		// customScroll()
		display flex
		align-items center
		justify-content center
		// flex-direction column
		// transform translateX(-50%)
		// padding 50px 70px
		padding 50px 40px
		border-radius 10px
		background linear-gradient(180deg, #445C83 29.86%, #6082B8 101.48%)
		background-color #445C83
		// text-align center
		font-size 24px
		line-height 1.5
		+mw(1600px)
			font-size 22px
		+mw(1300px)
			left 10%
			width 80%
		+mw(1100px)
			top 15px
			left 15px
			width calc(100% - 30px)
			height calc(100vh - 30px)
			padding 30px 20px
		/*+mw(800px)
			padding 25px 15px*/
		+mw(480px)
			// font-size 22px
			font-size 20px
			top 5px
			left 5px
			width calc(100% - 10px)
			height calc(100vh - 10px)
			padding 25px 10px
	.double-line
		left 0
		top 0
		transform translate(-40%,-5%)
		+mw(1500px)
			width 332px
			height 315px
		+mw(1100px)
			width 232px
			height 215px
		+mw(800px)
			display block
			width 222px
			height 205px
			// left 5px
			left -5%
		+mw(480px)
			width 152px
			height 135px
			// left -5%
	.polygon
		polygon()
		position absolute
		// width 55.5%
		// padding-top 60.90625%
		left 18%
		top 18.5%
		transform-origin 0 0
		transform scale(1.15)
		+mw(1100px)
			top 23%
			// top 18%
			left 23%
			transform scale(1.5)
		+mw(800px)
			top 25%
			left 25%
			transform scale(1.6)
		+mw(480px)
			top 30%
			transform scale(1.75)
		&:before
			transform rotate(45deg)
	.bg-triangle
		z-index 1
	&__text
		text-align center
		/*font-size 24px
		line-height 1.5
		+mw(480px)
			font-size 22px*/
		.title
			font-size 72px
			color $color2
			text-transform uppercase
			margin-bottom 10px
			+mw(1600px)
				font-size 60px
			+mw(1100px)
				font-size 50px
			+mw(800px)
				font-size 40px
			+mw(480px)
				font-size 36px
	.green
		color $color2
	&__title
		width 50%
		// align-self flex-start
		// padding-top 15vh
		padding-top 11vh
		padding-left 5%
		// padding-right 7%
		padding-right 8%
		+mw(1500px)
			padding-left 3.5%
			.green
				display block
		+mw(1100px)
			.text .green
				display inline
		+mw(800px)
			width 100%
			padding-top 0
			padding-left 0
			margin-bottom 45px
			.page-title
				padding-top 0
			/*.text
				text-align center*/
	&__form
		// width 50%
		height calc(100% - 30px)
		// align-self flex-start
		// overflow auto
		customScroll()
		display flex
		padding 10px 30px 0
		// margin-top 40px
		margin-top 30px
		&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb
			margin-top 10px
		+mw(800px)
			flex-wrap wrap
			// margin-top 70px
			// height calc(100% - 70px)
			margin-top 40px
			height calc(100% - 40px)
			// padding 0 18px
			&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb
				margin-top 0
		+mw(480px)
			margin-top 40px
			height calc(100% - 40px)
			padding 0 15px
	.page-title
		width 100%
		margin-bottom 1.2em
		position static
		&:before
			display none

#formpopup, #mrtPopup
	.popup-triangle2
		display none
	.popup__text
		display none
	+mw(800px)
		.popup-triangle1
			display none
