.treating-part
	display -webkit-flex
	display -moz-flex
	display -ms-flex
	display -o-flex
	display flex
	justify-content space-between
	align-items flex-start
	margin-bottom 100px
	+mw(1200px)
		flex-wrap wrap
		flex-direction column-reverse
		align-items center
		justify-content center
		padding-top 0
		margin-bottom 30px
	&__content
		padding-top 18vh
		padding-left 14%
		padding-right 15.2%
		position relative
		width calc(100% - 800px)
		+mw(1680px)
			padding-right 4%
			padding-top 20vh
		+mw(1480px)
			width calc(100% - 600px)
		+mw(1200px)
			width 100%
			padding 37px 113px 0	
		+mw(825px)
			padding 30px 30px 0			

		p, ul, ol, blockquote, table
			img
				margin-bottom 0
		h3, h4, h5, h6, ul, ol
			margin-bottom 21px
			line-height 30px
			+mw(768px)
				line-height 1.2
		blockquote, hr, table
			margin 50px 0
			+mw(1600px)
				margin 30px 0
		blockquote
			position relative
			padding-left 50px
			&:before
				content ''
				display block
				width 1px
				height 100%
				background $color2
				position absolute
				left 0
				top 0
		hr
			border-color $color2
		table
			border none
			border-collapse collapse
			td, th
				border 1px solid $color2
				padding 20px
				+mw(768px)
					padding 10px
			th
				font-weight normal
		caption
			// text-align left
			margin-bottom 15px
			text-transform uppercase
		h3
			font-size 30px
			font-weight normal
			color $color2
			+mw(768px)
				font-size 24px
		h4
			font-size 24px
			font-weight normal
			+mw(768px)
				font-size 20px
		h5
			font-size 20px
			font-weight normal
			+mw(768px)
				font-size 18px
		h6
			font-size 18px
			font-weight normal
			+mw(768px)
				font-size 16px
		a
			color $color2
			text-decoration-color transparent
			transition text-decoration-color .3s
			&:hover
				text-decoration-color #99c36c
		ol
			padding 0
			counter-reset numbers
			li
				&:before
					counter-increment numbers
					content counter(numbers) ". "
					margin-right 5px
					// font-weight normal
					color $color2
		ul
			li
				padding-left 50px
				position relative
				&:before 
					content '' 
					position absolute 
					top 1em 
					left 0
					width 35px 
					height 1px 
					background $color2
					+mw(768px)
						top 0.6em
		strong
			font-weight bold


	p
		margin-bottom 21px
		line-height 30px
		+mw(768px)
			line-height 1.2
	.david
		position relative
		+mw(1200px)
			padding 0
		p
			margin-bottom 0
			white-space pre-wrap
		img
			width 800px
			height auto 
			+mw(1480px)
				width 600px
			+mw(1200px)
				width auto
				height 600px
			+mw(825px)
				height 400px
			+mw(825px)
				height 290px
		
		&__text
			
			&.text-shoulder1
				top 12%
				left 30%
			&.text-shoulder2
				top 24%
				left 18%
				
			&.text-hip1
				top 21%
				right 35%
				.info
					left auto
					right -22px
			&.text-hip2
				top 43%
				right 17%
				.info
					left auto
					right -22px
			&.text-hip3
				top 70%
				right 25%
				.info
					left auto
					right -22px
			
			&.text-pelvic1
				top 65%
				left 47%
			
			&.text-knee1
				top 47%
				right 33%
				.info
					left auto
					right -22px
			&.text-knee2
				top 52%
				left 10%
			&.text-knee3
				top 36%
				left 6%
				.info
					top auto
					bottom 0
			&.text-knee4
				top 30%
				right 41%
				.info
					left auto
					right -22px
					top auto
					bottom 0
				
			&.text-foot1
				top 26%
				right 50%
				.info
					left auto
					right -22px
			&.text-foot2
				top 50%
				right 33%
				.info
					left auto
					right -22px
					top auto
					bottom 0
			&.text-foot3
				top 56%
				left 45%
			
			&.text-spine1
				top 22%
				left 26%
			&.text-spine2
				top 52%
				left 34.5%

			&.text-hands1
				bottom 15%
				left 32%
			&.text-hands2
				bottom 25%
				left 47%
			&.text-hands3
				top 91%
				left 15.5%

			.plus
				cursor pointer
				svg
					transition transform .3s ease	
			.info
				width 335px
			+mw(1200px)
				.info
					display none
				.plus
					&:hover
						border-color #fff
			&.active
				z-index 2
				.info
					opacity 1
				+mw(1200px)
					width 100%
					height 100%
					top 0
					left 0
					background-color $color2
					z-index 99
					transition all 0 ease
					.info
						transition opacity 0 ease
						display block
						padding 35px
						width 100%
						left 0
						top 0
					.plus
						right 0
						top 0
						border-color $color2
						&:hover
							border-color $color2
						svg
							transform rotate(45deg) scale(1.3)
		.info
			opacity 0
			pointer-events none
			transition opacity .3s ease
			
		
.arr
	arr()
	position absolute
	z-index 30
	// left 12.5%
	left 8%
	top 40vh
	// +mw(1680px)
	// 	top 33vh
	+mw(1200px)
		display none
	&_l
		transform rotate(-45deg)
	&_m
		display none
		+mw(1200px)
			display block
			top 100px
			left 8%
			width 30px
			height 30px
	&:after 
		+mw(1200px)
			width 25px
			height 25px
			right -1px
			top 5px
			