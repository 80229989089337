.service-block
	padding-top 190px
	+mw(1600px)
		padding-top 140px
	+mw(1200px)
		padding-top 40px
	+mw(768px)
		padding-top 20px
	.triangle3
		right auto
		left -7%
		top 12%
	.triangle2
		bottom auto
		top 25%
		left auto
		right -3%
		+mw(480px)
			display none
	.triangle1
		bottom auto
		top 40%
		right -4%
		+mw(480px)
			display none
		

.mrt-top
	display flex
	justify-content space-between
	// margin 0 7.5%
	padding-bottom 80px
	+mw(1200px)
		margin 0
		padding-bottom 20px
	+mw(800px)
		flex-wrap wrap
	+mw(480px)
		padding 0
	&__img
		width 53.125%
		// width 57.5%
		text-align right
		+mw(800px)
			width 100%
			order 1
		+mw(480px)
			padding-left 15px
		img
			max-width 110%
			width 110%
			margin-right -10%
			margin-top calc(-190px - 10vh)
			+mw(1600px)
				margin-top -140px
			+mw(1200px)
				margin-top -40px
			+mw(950px)
				margin-top 0
			+mw(800px)
				width 85%
				// width 75%
				margin-top -90px
				margin-bottom 40px
			+mw(480px)
				margin-top 0
				width auto
	&__text
		width 46.875%
		// width 42.5%
		// padding-left 15.625%
		padding-left 13.8744%
		line-height 30px
		+mw(1200px)
			padding-left 7.5%
			padding-right 10px
		+mw(800px)
			width 100%
			order 2
			padding 0 7.5%
		+mw(768px)
			padding 0 15px
			text-align justify
		+mw(480px)
			text-align left
		p
			margin-bottom 21px
	.btn
		margin-top 58px
		+mw(800px)
			margin 40px auto 0


.mrt-content
	margin 0 40px
	padding-bottom 80px
	+mw(1200px)
		margin 0
		padding-bottom 20px
	h2
		font-size 50px
		font-weight 300
		color $color2
		margin-bottom 50px
		margin-top 100px
		+mw(1600px)
			font-size 45px
			// margin-bottom 30px
			// margin-top 70px
			margin-bottom 35px
			margin-top 70px
		+mw(1200px)
			font-size 28px
	h4
		font-size 24px
		font-weight 300
		margin-bottom 30px
		margin-top 50px
		+mw(1200px)
			font-size 20px
	p
		margin-bottom 30px
	h4, p
		padding 0 5%
		line-height 30px
		+mw(1200px)
			padding 0
	ul, ol
		padding 10px 5%
		+mw(1200px)
			padding 0
	li
		line-height 30px
		margin-bottom 16px
		&:last-child
			margin-bottom 0
	ul
		li
			padding-left 50px
			position relative
			&:before 
				content '' 
				position absolute 
				top 1em 
				left 0
				width 35px 
				height 1px 
				background $color2
	
.mrt-table-wrap
	padding 0 5%
	margin-top 150px
	margin-bottom 20px
	+mw(1200px)
		padding 0
		margin-top 70px
	+mw(800px)
		margin-top 50px
	table
		width 100%
		border none
		border-collapse collapse
		+mw(640px)
			display block
		tbody, tr, th, td
			+mw(640px)
				display block
		tr:nth-child(even)
			background rgba(255, 255, 255, 0.06)
			background-blend-mode soft-light
		th
			padding 24px
			font-size 30px
			font-weight 300
			color $color2
			line-height 30px
			text-align left
			+mw(1600px)
				font-size 28px
			+mw(1200px)
				font-size 26px
			/*+mw(768px)
				font-size 24px
			+mw(480px)
				font-size 20px*/
			&:nth-child(2)
				+mw(640px)
					display none
		td
			font-size 24px
			padding 17px 24px
			+mw(640px)
				padding 15px 24px
			&:first-child
				+mw(640px)
					padding-top 24px
			&:last-child
				text-align right
				+mw(640px)
					text-align center
					padding 24px
				+mw(480px)
					padding 14px 11px
			&:nth-child(2)
				white-space nowrap
			+mw(1600px)
				font-size 22px
			+mw(1200px)
				font-size 20px
			/*+mw(768px)
				padding 10px
				font-size 18px
			+mw(480px)
				font-size 16px*/

.order-btn
	display inline-flex
	justify-content center
	align-items center
	text-align center
	color $color2
	width 177px
	height 46px
	border-radius 5px
	background transparent
	border 1px solid $color2
	text-decoration none
	line-height 1
	transition .3s
	+mw(640px)
		width 100%
	&:hover
		background $color2
		color #fff
	&.disable, &:disabled
		background rgba(#7ED321,0.4)
		cursor default



//- mrt-photogallery
.mrt-slider-wrap
	position relative
	margin-bottom 100px
	+mw(1200px)
		margin-bottom 80px
	+mw(800px)
		margin-bottom 70px

.mrt-slider-tabs
	position relative
	margin-bottom 25px
	padding-bottom 47%
	+mw(1100px)
		margin-bottom 10px
	+mw(800px)
		padding-bottom 0
		margin-bottom 0
	&__item
		display block
		position absolute
		left 0
		top 0
		width 100%
		height 100%
		z-index 0
		opacity 0
		visibility hidden
		border-bottom none
		transition .3s
		&.current
			opacity 1
			visibility visible
			z-index 1
	.image
		width 100%
		height 100%
		position absolute
		left 0
		top 0
		z-index 10
		margin 0
		bg()
		transform none

.mrt-slider-thumbs-wrap
	position relative
	+mw(800px)
		padding-bottom 40px
	.arr
		top 50%
		cursor pointer
		&.arr-next
			right 1.5%
			left auto
			transform translateY(-50%) rotate(135deg)
		&.arr-prev
			left 1.5%
			transform translateY(-50%) rotate(-45deg)
		&.swiper-button-disabled
			opacity 0
			visibility hidden
			pointer-events none
			cursor default
	.pagination
		display none
		text-align center
		+mw(800px)
			display block
			width 100%
			position absolute
			bottom 0
			left 0
		.swiper-pagination-bullet
			border 2px solid $color2
			background-color transparent
			opacity 1
			cursor pointer
			outline none
			margin 0 4px
		.swiper-pagination-bullet-active
			background-color $color2

		
.mrt-slider-thumbs
	position relative
	overflow hidden
	&__item
		position relative
		cursor pointer
		&:before
			content ''
			position absolute
			left 0
			top 0
			z-index 10
			pointer-events none
			width 100%
			height 100%
			background #20272f
			opacity 0.42
			transition opacity .5s
			+mw(800px)
				display none
		&.current
			&:before
				opacity 0
		&:hover:not(.current)
			&:before
				opacity 0
	.image
		position relative
		padding-bottom 53.5%
		bg()
		+mw(768px)
			padding-bottom 60%