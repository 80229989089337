@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/mixins.styl'

h1,h2,h3,h4,h5,h6
	margin-top 0

ul,li
	list-style-type none
	margin 0
	padding 0

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

p
	margin 0

a
	color inherit

.logo img
	display block

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle
	max-width 100%

textarea
	resize none

section
	position relative

input,
select,
button
	outline none

[type='submit']
	cursor pointer

*, *:before, *:after
	box-sizing border-box

@media (min-width:1101px)
	a,[type=submit]
	// user-select: none
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0)



/*site styles*/
html
	font-size $baseFontSize;
	min-height 100%;
	line-height 1.2;

body
	font-family $ff
	font-weight 300
	color $color
	background $bg
	background #384c6c
body.home
	background #384c6c
	background -moz-linear-gradient(top,  #384c6c 0%, #384c6c 28%, #5f82b8 52%, #384c6c 76%, #384c6c 100%)
	background -webkit-linear-gradient(top,  #384c6c 0%,#384c6c 28%,#5f82b8 52%,#384c6c 76%,#384c6c 100%)
	background linear-gradient(to bottom,  #384c6c 0%,#384c6c 28%,#5f82b8 52%,#384c6c 76%,#384c6c 100%)

h1
	font-size 50px
	font-weight lighter
.wrapper
	position relative
	display flex
	flex-direction column
	justify-content space-between
	min-height calc(100vh)

.page-main
	flex-grow 1
	position relative
	overflow hidden
	&__content
		padding-top 20vh
		+mw(1600px)
			padding-top 18vh
		+mw(1200px)
			padding-top 160px
		+mw(768px)
			padding-top 100px

.container
	width 85%
	margin 0 auto
	+mw(768px)
		width 100%
		padding 0 15px

.btn
	btn()
	width 400px
	+mw(1600px)
		width 300px
	+mw(768px)
		width 290px
	+mw(480px)
		width 270px

@keyframes btn-blink
	0%
		background $color2
	40%
		background $color2
	45%
		background #fff
	50%
		background $color2
	55%
		background #fff
	60%
		background $color2
	100%
		background $color2

.btn-fixed
	btn-fixed()
	// animation btn-blink 6s ease infinite
	+mw(800px)
		display none
	span
		position relative
		z-index 10
	&:before
		content ''
		display block
		width 100%
		height 100%
		border-radius 5px
		position absolute
		left 0
		top 0
		z-index 3
		pointer-events none
		animation btn-blink 6s ease infinite
	&:hover
		&:before
			animation none

.btn-virtual
	btn()
	position absolute
	left calc(50% - 165px - 110px)
	bottom 45px
	z-index 100
	width 660px
	+mw(1600px)
		width 480px
		left calc(50% - 60px - 110px)
	+mw(1200px)
		bottom 45px
		left calc(50% - 150px - 50px)
	+mw(800px)
		bottom 70px
		left calc(50% - 240px)
	+mw(600px)
		width 300px
		padding 0 15px
		left 0
		right 0
		margin 0 auto
	+mw(480px)
		width 270px
	.icon
		width 48px
		height 35px
		margin-left 25px
		+mw(1600px)
			margin-left 15px
			width 34px
			height 30px
			+mw(600px)
				margin-left 5px

.section-title, .page-title
	page-title()

.section-title
	+mw(1200px)
		padding-top 70px

.page-title
	+mw(768px)
		padding-top 50px
	h1
		font-weight 300
		margin 0 0 15px
		font-size 50px
		+mw(1600px)
			font-size 38px
		+mw(1200px)
			font-size 28px
	.a-date
		font-size 16px
		margin-left 0

.back-title
	backTitle()

.double-line
	double-line()

.more
	display inline-block
	font-size 18px
	color $color2
	text-transform uppercase
	line-height 1.5
	hover($color2)




//Модули
// @import "parts/parts.styl"
@import "include/include.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index.styl'
@import 'pages/home.styl'
@import 'pages/treating.styl'
@import 'pages/style404.styl'
@import 'pages/we-work.styl'
@import 'pages/treating-part-of-the-body.styl'
@import 'pages/doctors.styl'
@import 'pages/news.styl'
@import 'pages/news-text.styl'
@import 'pages/contacts.styl'
@import 'pages/gallery.styl'
@import 'pages/clinic.styl'
@import 'pages/mrt.styl'
