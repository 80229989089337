.news-text
	padding 0 20%
	line-height 30px
	padding-bottom 25px
	+mw(800px)
		padding 0
	img
		margin-bottom 55px
		+mw(1600px)
			margin-bottom 35px
	p, ul, ol, blockquote, table
		img
			margin-bottom 0
	p, h1, h2, h3, h4, h5, h6, ul, ol
		margin-bottom 50px
		+mw(1600px)
			margin-bottom 30px
	blockquote, hr, table
		margin 50px 0
		+mw(1600px)
			margin 30px 0
	blockquote
		position relative
		padding-left 50px
		&:before
			content ''
			display block
			width 1px
			height 100%
			background $color2
			position absolute
			left 0
			top 0
	hr
		border-color $color2
	table
		border none
		border-collapse collapse
		td, th
			border 1px solid $color2
			padding 20px
			+mw(768px)
				padding 10px
		th
			font-weight normal
	caption
		// text-align left
		margin-bottom 15px
		text-transform uppercase
	h1
		font-size 50px
		font-weight normal
		+mw(1600px)
			font-size 45px
		+mw(768px)
			font-size 30px
	h2
		font-size 40px
		font-weight normal
		+mw(1600px)
			font-size 38px
		+mw(768px)
			font-size 28px
	h3
		font-size 30px
		font-weight normal
		+mw(768px)
			font-size 24px
	h4
		font-size 24px
		font-weight normal
		+mw(768px)
			font-size 20px
	h5
		font-size 20px
		font-weight normal
		+mw(768px)
			font-size 18px
	h6
		font-size 18px
		font-weight normal
		+mw(768px)
			font-size 16px
	a
		color $color2
		text-decoration-color transparent
		transition text-decoration-color .3s
		&:hover
			text-decoration-color #99c36c
	ol
		padding 0
		counter-reset numbers
		li
			&:before
				counter-increment numbers
				content counter(numbers) ". "
				margin-right 5px
				// font-weight normal
				color $color2
	ul
		li
			padding-left 50px
			position relative
			&:before 
				content '' 
				position absolute 
				top 1em 
				left 0
				width 35px 
				height 1px 
				background $color2
	strong
		font-weight bold
				