@keyframes rotate-triangle1
	0%
		transform rotate3d(1,1,1,0deg)
	50%
		transform rotate3d(1,1,1,180deg)
	100%
		transform rotate3d(1,1,1,360deg)

@keyframes rotate-triangle2
	0%
		transform rotate3d(1,1,1,30deg)
	50%
		transform rotate3d(1,1,1,210deg)
	100%
		transform rotate3d(1,1,1,390deg)

@keyframes rotate-triangle3
	0%
		transform rotate3d(-1,-1,-1,180deg)
	50%
		transform rotate3d(-1,-1,-1,360deg)
	100%
		transform rotate3d(-1,-1,-1,540deg)

.bg-triangle
	position absolute
	z-index -1
	pointer-events none
	svg
		width 100%
		height auto
		/*perspective 400px*/
		perspective 800px
		transform-style preserve-3d
		// animation rotate-triangle 10s linear infinite
		path
			transform-origin 50% 50%
			transform-style preserve-3d
	.tr
		position absolute
		left 0
		top 0
		width 100%
	.tr1
		z-index 1
	.tr2
		z-index 2
	.tr3
		z-index 3

// 404 triangles
.bg-triangle1
	width 27%
	// width 28.17%
	left -8.3%
	top 17.5%
	.path1-tr1
		animation rotate-triangle1 20s linear infinite
	.path2-tr1
		animation rotate-triangle2 10s linear infinite
	.path3-tr1
		animation rotate-triangle3 30s linear infinite
	+mw(1100px)
		top 30%
		left -5%
	+mw(480px)
		display none

.bg-triangle2
	width 49.3%
	right -8.4%
	top -18.5%
	// width 86%
	// right -26.7%
	// top -60.2%
	/*width 30.8%
	right 0.88%
	top 2.5%*/
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite
	+mw(480px)
		top 0
		right -25%
		width 105%
		// width 200%
		// top -30%
		// right -80%
		/*width 60%*/

.bg-triangle3
	width 24.6%
	left 27.1%
	bottom -26%
	.path1-tr3
		animation rotate-triangle1 40s linear infinite
	.path2-tr3
		animation rotate-triangle2 30s linear infinite
	.path3-tr3
		animation rotate-triangle3 50s linear infinite

.bg-triangle4
	width 11%
	right 10.3%
	bottom 0
	.path1-tr4
		animation rotate-triangle3 50s linear infinite
	.path2-tr4
		animation rotate-triangle1 40s linear infinite
	+mw(800px)
		width 22%
	+mw(480px)
		width 40%
		// width 30%

.bg-triangle5
	width 7.5%
	left 41.8%
	top 17%
	svg
		animation rotate-triangle1 40s linear infinite

// home triangles
.triangle1
	width 30%
	right -2%
	bottom -10%
	// width 86%
	// right -26.7%
	// top -60.2%
	/*width 30.8%
	right 0.88%
	top 2.5%*/
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite
	+mw(480px)
		top 0
		right -25%
		width 105%
		// width 200%
		// top -30%
		// right -80%
		/*width 60%*/

.triangle2
	width 25%
	left -2%
	bottom -8%
	.path1-tr2
		animation rotate-triangle3 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle1 40s linear infinite

.triangle3
	// width 40%
	// right -9%
	// top -10%
	width 27%
	// width 28.17%
	right -3%
	top 5%
	.path1-tr1
		animation rotate-triangle1 20s linear infinite
	.path2-tr1
		animation rotate-triangle2 10s linear infinite
	.path3-tr1
		animation rotate-triangle3 30s linear infinite
	+mw(1100px)
		top 30%
		left -5%
	+mw(480px)
		display none


.triangle4
	width 27%
	right 3%
	top 36%
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite
	+mw(1100px)
		right 3%
		top 36%
	+mw(480px)
		display none
		
.triangle5
	width 17%
	left 3%
	top 59%
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite
	+mw(1100px)
		top 60%
		left -5%
	+mw(480px)
		display none

.triangle6
	width 10%
	right 3%
	bottom 10%
	transform rotate(45deg)
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite
	+mw(1100px)
		right 3%
		bottom 10%
	+mw(480px)
		display none


// news triangle
.triangle-news
	width 30%
	right -5%
	bottom 17%
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite


.triangle-contacts
	width 30%
	left -1%
	bottom 12%
	.path1-tr2
		animation rotate-triangle1 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle3 40s linear infinite


.popup-triangle1
	width 45%
	bottom -16%
	left -2%
	.path1-tr2
		animation rotate-triangle3 30s linear infinite
	.path2-tr2
		animation rotate-triangle2 20s linear infinite
	.path3-tr2
		animation rotate-triangle1 40s linear infinite
	+mw(1100px)
		// width 60%
		bottom -12%
	+mw(800px)
		width 70%
		left -8%
		bottom -10%
	+mw(480px)
		width 95%
		left -15%

.popup-triangle2
	width 37%
	right -3%
	top 5%
	.path1-tr1
		animation rotate-triangle1 20s linear infinite
	.path2-tr1
		animation rotate-triangle2 10s linear infinite
	.path3-tr1
		animation rotate-triangle3 30s linear infinite
	+mw(480px)
		width 57%
		right -9%
