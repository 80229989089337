
// plaseholders
input::-webkit-input-placeholder
	color #ffffff
	font-weight 300
input::-moz-placeholder
	color #ffffff
	font-weight 300
input:-moz-placeholder
	color #ffffff
	font-weight 300
input:-ms-input-placeholder
	color #ffffff
	font-weight 300
textarea::-webkit-input-placeholder
	color: #ffffff
	font-weight 300
textarea::-moz-placeholder
	color #ffffff
	font-weight 300
textarea:-moz-placeholder
	color: #ffffff
	font-weight 300
textarea:-ms-input-placeholder
	color #ffffff
	font-weight 300

input:focus::-webkit-input-placeholder
	opacity 0
input:focus::-moz-placeholder
	opacity 0
input:focus::-moz-placeholder
	opacity 0
input:focus::-ms-input-placeholder
	opacity 0
textarea:focus::-webkit-input-placeholder
	opacity 0
textarea:focus::-moz-placeholder
	opacity 0
textarea:focus::-moz-placeholder
	opacity 0
textarea:focus::-ms-input-placeholder
	opacity 0	

	
input:not([type="checkbox"]):not([type="radio"]), textarea
	display block
	width 100%
textarea 
	resize none

.contacts-form
	width 50%
	+mw(950px)
		width 100%
		margin-top 80px
	+mw(768px)
		margin-top 40px

.send-form
	width 50%
	// width 100%
	// margin-top 40px
	+mw(800px)
		width 100%
	.form-item
		margin-bottom 36px
		.description
			background #445C83
	.form-check
		margin-bottom 36px

.contacts-form, .send-form
	// width 50%
	position relative
	font-size 20px
	font-weight 300
	color $color
	/*+mw(950px)
		width 100%
		margin-top 80px
	+mw(768px)
		margin-top 40px*/
	+mw(480px)
		font-size 18px
	.form-action-wrap
		display flex
		// justify-content flex-end
		justify-content space-between
		align-items center
		// margin-top 42px
	.captcha
		width 50%
		+mw(600px)
			width 100%
	.form-action
		width 50%
		position relative
		text-align right
		.btn
			width 250px
			display inline-flex
			outline none
			border none
			+mw(1600px)
				width 180px
				height 70px
	.g-recaptcha
		position relative
	#error-text-captcha
		font-size 14px
		color #ff0000
		line-height 20px
		position absolute
		top 100%
		left 0
		pointer-events none

.contacts-form
	.form-action-wrap
		+mw(1600px)
			flex-direction column
			align-items flex-end
		+mw(950px)
			flex-direction row
			align-items center
		+mw(800px)
			flex-direction column		
	.form-action
		+mw(1600px)
			width 100%
			margin-top 40px
		+mw(950px)
			width 50%
			margin-top 0
		+mw(800px)
			width 100%
			text-align center
			margin-top 40px
			
.send-form
	.form-action-wrap
		+mw(1800px)
			flex-direction column
			align-items flex-end
		+mw(800px)
			align-items center
	.form-action
		+mw(1800px)
			width 100%
			margin-top 40px
		+mw(800px)
			text-align center

.form-item
	position relative
	margin-bottom 40px
	// &.textarea
	
	input, select, textarea
		font-family $ff
		font-weight 300
		color $color
		/*&:focus
			border-color $blue*/
		&:focus
			+ .description
				opacity 1

	input, select
		display block
		width 100%
		height 65px
		padding 0 30px
		// padding 18px 30px 17px
		background transparent
		border 1px solid #fff
		border-radius 5px
		outline none
		transition border-color .3s ease
		+mw(480px)
			padding 0 15px
	select
		-ms-appearance none
		appearance none
	
	textarea
		display block
		width 100%
		height 200px
		padding 18px 30px 17px
		background transparent
		border 1px solid #fff
		border-radius 5px
		outline none
		transition border-color .3s ease
		customScroll()
		&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb
			border-top-right-radius 5px
			border-bottom-right-radius 5px
		+mw(480px)
			padding 18px 15px 17px
	.description
		position absolute
		top 0
		left 28px
		transform translateY(-50%)
		padding 0 15px
		font-size 16px
		line-height 20px
		color $color
		background $bg
		opacity 0
		transition opacity .1s
		+mw(480px)
			left 18px

	.error-text
		font-size 14px
		color #ff0000
		line-height 20px
		position absolute
		top 102%
		left 0
		opacity 0
		pointer-events none
		transition opacity .3s ease
	
	&.active
		.description
			opacity 1
		/*.info-text
			opacity 0
		.select2-container--default .select2-selection--single
			border-color $blue*/

	&.error, &.has-error
		.error-text
			opacity 1
		/*.description
			opacity 1*/
		input, textarea, select
			border-color #ff0000
		.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow b
			border-color #ff0000
	
	&.ok
		.description
			opacity 1
		/*input, select, .select2-container--default .select2-selection--single
			border-color $blue
			// border-color #8cc640*/


.form-check
	font-size 16px
	padding-left 40px
	margin-bottom 40px
	position relative
	[type="checkbox"]
		display none
	&.error, &.has-error
		label:before
			border-color #ff0000
	label
		position relative
		&:before
			content ''
			display block
			width 27px
			height 27px
			border 1px solid #fff
			border-radius 5px
			position absolute
			left -40px
			top 50%
			transform translateY(-50%)
		&:after
			content ''
			display block
			width 10px
			height 10px
			border-bottom  2px solid $color2
			border-left  2px solid $color2
			transform rotate(-45deg)
			margin-top -7px
			position absolute
			left -32px
			top 50%
			// top 3px
			opacity 0
			transition opacity .3s
	[type="checkbox"]:checked + label:after
		opacity 1
	a
		color $color2
		text-decoration-color #64ac3a
		transition text-decoration-color .3s
		&:hover
			text-decoration-color transparent


.select2-container
	z-index 1200
.form-item .select2-container
	z-index 1
.select2-container--default .select2-selection--single
	border-radius 5px
	border 1px solid #fff
	background transparent
.select2-container .select2-selection--single
	height 65px
.select2-container--default .select2-selection--single .select2-selection__rendered
	color $color
	// line-height 65px
.select2-container .select2-selection--single .select2-selection__rendered
	padding 18px 35px 17px 30px
	+mw(480px)
		padding 18px 35px 17px 15px
.select2-selection:focus
	outline transparent
.select2-container--default .select2-selection--single .select2-selection__arrow
	width 15px
	right 20px
	top 15px
.select2-container--default .select2-selection--single .select2-selection__arrow b
	width 10px
	height 10px
	border-right none
	border-top none
	border-bottom  2px solid #fff
	border-left  2px solid #fff
	transform rotate(-45deg)
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b
	border-right none
	border-top none
	border-bottom 2px solid #fff
	border-left 2px solid #fff
	transform rotate(135deg)

.select2-dropdown
	border 1px solid #fff
	background-color $bg
	// border-top none
	// border-bottom none
	// border-left 1px solid #bababa
	// border-right 1px solid #bababa
	border-radius 5px
	customScroll()
.select2-container--default .select2-results>.select2-results__options
	customScroll()
	max-height 240px
.select2-container--default .select2-results__option[aria-disabled=true]
	display none
.select2-results__option
	color $color
	font-size 20px
	font-weight 300
	// height 60px
	min-height 60px
	padding 15px 30px
	display flex
	align-items center
	// border-bottom 1px solid #bababa
	// position relative
.select2-container--default .select2-results__option--highlighted[aria-selected]
	background #6082B8
.select2-container--default .select2-results__option[aria-selected=true]
	background-color #6082B8
.select2-container--default .select2-selection--single .select2-selection__placeholder
	color $color




/* == media == */
// @media $xlg

// @media $lg

// @media $md

// @media $sm

// @media $xs
