.contacts-block
	// padding-top 20vh
	padding-top 190px
	+mw(1600px)
		padding-top 140px
	+mw(1200px)
		padding-top 40px
	+mw(768px)
		padding-top 20px
	&__top, &__map
		margin 0 7.5%
		padding-bottom 80px
		+mw(1200px)
			margin 0
	&__map
		margin-top 70px
		+mw(1600px)
			margin-top 40px
		img
			width 100%
	&__top
		display flex
		justify-content space-between
		+mw(950px)
			flex-wrap wrap
