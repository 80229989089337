/*.treating
	+mw(1200px)
		.page-title
			padding-top 0*/

.davids-block
	padding-top 12vh
	// padding-top 5%
	padding-bottom 7.5%
	position relative
	+mw(1600px)
		padding-top 0
		padding-bottom 5%
	+mw(1200px)
		display none

.davids-block-mob
	display none
	+mw(1200px)
		display block
		padding-top 30px
		margin-bottom 60px
		+mw(800px)
			margin-bottom 0
		.swiper-wrapper
			align-items center
			margin-bottom 20px
		.swiper-slide
			opacity 0 !important
		.swiper-slide-active
			opacity 1 !important		
		.item
			display -webkit-flex
			display -moz-flex
			display -ms-flex
			display -o-flex
			display flex
			flex-direction column
			align-items center
			&__title
				margin-top 25px
				text-decoration none
				text-align center
				padding 0 15px
			img
				max-height 290px
			&__img-outer
				max-width 290px
				position relative
		.david-1
			.plus
				top 45%
				left 44%
		.david-2
			.plus	
				top 45%
				left 26%
				// top 38%
				// left 32%
		/*.david-1
			.plus	
				top 38%
				left 32%
		.david-2
			.plus
				bottom 0
				left 64%*/
		.david-3
			.plus
				top 60%
				left 40%
				// top 42%
				// left 56%
		.david-4
			.plus
				top 20%
				left 62%
				// top 3%
				// left 25%
		.david-5
			.plus
				top 42%
				left 27%
				// top 14%		
				// left 50%			
		.david-6
			.plus
				top 63%
				left 28%
				// top 2%
				// left 11%	
		.david-7
			.plus
				top 22%
				left 4%
		/*.david-6
			.plus
				top 45%
				left 60%
				// top 35%
				// left 58%*/
					
		.pagination
			text-align center
			.swiper-pagination-bullet
				border 2px solid $color2
				background-color transparent
				opacity 1
				cursor pointer
				outline-color transparent
			.swiper-pagination-bullet-active
				background-color $color2

.davids-list
	display flex
	flex-wrap wrap
	justify-content space-between
	align-items center
	+mw(1200px)
		justify-content center
	+mw(800px)
		margin-top 60px
	&__item
		width calc((100% - 2*60px)/3)
		margin-bottom 90px
		font-size 18px
		+mw(1600px)
			width calc((100% - 2*40px)/3)
			margin-bottom 60px
		+mw(1200px)
			width 50%
			padding 0 25px
			margin-bottom 30px
		+mw(768px)
			width 100%
			padding 0
		.title
			font-size 24px
			font-weight 300
			margin-bottom 20px
			display block
			cursor pointer
			+mw(768px)
				margin-bottom 15px
			a
				text-decoration none
				transition color .3s
				&:hover
					color $color2
					// hover()
		.text
			margin-bottom 20px
			+mw(768px)
				margin-bottom 15px
			p
				margin-bottom 0

.polygon-david
	polygon()
	position absolute
	left 25%
	top 25vh
	&:before
		transform rotate(103.53deg)

