.gallery3
	.section-gallery 
		+mw(960px)
			padding-bottom 60px

.section-gallery 
	width 100%
	height 100%
	display flex
	justify-content flex-end
	padding 45px 0
	/*+mw(1600px)
		padding 20px 0*/
	+mw(960px)
		// padding 20px 0
		padding 20px 0 50px
		flex-direction column
		justify-content center
		align-items center
		// padding-bottom 60px
	&__info
		display flex
		flex-direction column
		justify-content center
		// width 46%
		// width 38.5%
		// margin-left 7.5%
		// padding-left 7.5%
		// padding-right 7.5%
		width 320px
		margin-right 8%
		flex-shrink 0
		font-size 16px
		color $color
		+mw(1200px)
			width auto
			max-width 320px
			/*margin-right 0
			margin-left 7.5%*/
			margin-right 7.5%
		+mw(960px)
			margin-right 0
			margin-left 0
		+mw(480px)
			width 85%
		&.top
			padding-top 5%
			// padding-top 10%
			+mw(1200px)
				padding-top 0
	&__title
		margin 0 0 30px 0
		font-size 50px
		font-weight 300
		+mw(1600px)
			font-size 38px
		+mw(960px)
			font-size 28px
	&__text
		line-height 1.8
	
	.all-btn
		position absolute
		right auto
		top auto
		left 7.5%
		bottom 20vh
		+mw(1600px)
			bottom 18vh
		+mw(1200px)
			position static
			margin-top 30px
		+mw(960px)
			position absolute
			bottom 0
			margin-top 0
	
	&__slider
		position relative
		// width 54%
		width 60%
		height 100%
		padding-left 110px
		+mw(1200px)
			padding-left 50px
		+mw(960px)
			width 85%
			padding-left 0
			padding-top 30px

.gallery1
	.section-gallery__info
		font-size 24px
		+mw(1600px)
			font-size 20px
		+mw(960px)
			font-size 18px

		
.section-slider
	position relative
	width 100%
	height 100%
	overflow hidden
	+mw(960px)
		margin-bottom 40px
	&__item
		position relative
		width 100%
		height 100%
		overflow hidden
		+mw(1200px)
			height 70vh
		+mw(960px)
			height 50vh
		.img
			width 100%
			height 100%
			bg()
		.icon
			position absolute
			bottom 40px
			right 70px
			width 115px
			z-index 5
			+mw(960px)
				bottom 10px
				right 10px
			img 
				transition transform .3s
			&:hover img
				transform  skew(15deg, 15deg)
.section-slider-pagination
		position absolute
		z-index 10
		top 0
		left 0
		height 100%
		display flex
		flex-direction column
		justify-content center
		counter-reset numbers
		+mw(960px)
			width 100%
			height auto
			top auto
			bottom 0
			// bottom -40px
			flex-direction row
		.swiper-pagination-bullet
			position relative
			display block
			background none
			margin 40px 0
			color $color2
			font-family $ff
			font-size 16px
			outline none
			opacity 1
			+mw(1600px)
				margin 30px 0
			+mw(960px)
				margin 0 4px
				border 2px solid $color2
				background-color transparent
				opacity 1
				cursor pointer
				outline-color transparent
			&:hover
				&:before
					transform scale(2)
			&.swiper-pagination-bullet-active
				color #fff
				+mw(960px)
					background-color $color2
				&:before
					transform scale(5) translate(-.5em, .3em)
					+mw(1600px)
						transform scale(4) translate(-.5em, .3em)
					+mw(1200px)
						transform scale(3) translate(-.5em, .3em)
				&:after
					transform none
			&:before
				content ''
				display block
				counter-increment numbers
				content counter(numbers,  decimal-leading-zero) ''
				transition transform .3s
				transform-origin right bottom 
				+mw(960px)
					display none
			&:after
				content ''
				position absolute
				top 2em
				left -4em
				width 15em
				height 1px
				background $color2
				transform scale(0,1)
				transition transform .3s
				transform-origin left center
				+mw(1200px)
					width 10em
				+mw(1600px)
					left -3em
				+mw(960px)
					display none
			
.section-gallery 
	&.right-gallery
		justify-content flex-start
		+mw(960px)
			flex-direction column-reverse
			justify-content center
		.section-gallery__slider
			padding-left 0
			padding-right 110px
			+mw(960px)
				padding-right 0
		.section-gallery__info
			margin-right 0
			margin-left 8%
			+mw(960px)
				margin-left 0
		.section-slider-pagination
			left auto
			right 0	
		.swiper-pagination-bullet
			&:after
				left auto
				right -1em
				height 1px
				transform-origin right center

.gallery-title
	backTitle()
	top 10%
	left -1%

.polygon1, .polygon2, .polygon3
	polygon()
	position absolute
	// left 25%
	// top 25vh

.polygon1
	bottom -12vh
	// top 15vh
	left 30%
	transform scale(0.99)
	&:before
		transform rotate(15.53deg)

.polygon2
	bottom -12vh
	// top 15vh
	left 40%
	transform scale(0.99)
	&:before
		transform rotate(42deg)

.polygon3
	bottom 11vh
	left 31%
	transform scale(0.99)
	&:before
		transform rotate(-15.53deg)



// news-slider
.news-slider-wrapper
	position relative
	// padding-top 20%
	top 39vh
	// transform translate(0,-50%)
	// top calc(100vh - 50%)
	overflow hidden
	margin 0  0 0 7.5%
	+mw(1200px)
		top auto
	+mw(960px)
		padding-top 30px
		
.news-slider
	position relative
	width 110%
	margin-right -5%
	+mw(960px)
		width 150%
		margin-right -25%
		margin-bottom 60px
	&__item
		position relative
	&__link
		text-decoration none
		display block	
		.img 
			position relative
			padding-bottom 100%
			filter grayscale(100%)
			&:before
				content ''
				position absolute
				top 0
				left 0
				width 100%
				height 100%
				background rgba(32, 39, 47, 0.7)
				transition opacity .5s
			&:after
				content ''
				position absolute
				bottom 0
				left 0
				width 100%
				height 70%
				background linear-gradient(to bottom,  rgba(42,32,40,0) 0%,rgba(42,32,40,0.85) 100%)
				opacity 0
				transition opacity .3s
			bg()
		&:hover
			.img
				filter none
				&:before
					opacity 0
				&:after
					opacity 1
	&__text
		position absolute
		z-index 2
		bottom 0
		left 0
		width 100%
		padding 0 30px 30px
		color $color
		font-size 30px
		font-weight 300
		line-height 1.25
		+mw(1600px)
			font-size 24px
		+mw(1200px)
			font-size 20px
		+mw(960px)
			font-size 18px
			padding 0 10px 10px
		.title
			margin-bottom 15px
		.date
			font-size 12px
			color #C4C4C4

.news-arr
	cursor pointer
	&.right
		display none
	&.left
		arr()
		position absolute
		right 18%
		top 50%
		margin-top -40px
		z-index 10
		transform rotate(135deg)
		+mw(1200px)
			display none
		&:before
			width 400px
			height 400px
			content ''
			position absolute
			left -400%
			top -400%
			transform rotate(45deg)
			z-index -1
			
.all-btn
	allBtn()
	position absolute
	z-index 20	
	top 23%
	right 7.5%
	+mw(1200px)
		top 18%
	+mw(960px)
		top auto
		bottom 0
		right auto
		left 7.5%

	
