.clinic
	padding-top 170px
	+mw(1200px)
		padding-top 35px
	&__title
		font-size 50px
		line-height 45px
		color $color2
	&__gallery
		margin-top 170px
	&__inner
		margin-top 70px
	.bottom-block
		margin-top 170px


.openNav
	.tour
		z-index 900

.tour
	position relative
	width 100%
	padding-top 48%
	z-index 1001
	iframe
		position absolute
		left 0
		top 0
		width 100%
		height 100%

	img 
		position absolute
		left 0
		top 0
		width 100%
		height 100%
		/*margin auto
		width 100%
		height auto*/
