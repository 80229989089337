.we-work
	.page-main__content
		padding-top 100px

.works
	padding-top 20vh
	+mw(1200px)
		padding-top 30px
	.item
		display -webkit-flex
		display -moz-flex
		display -ms-flex
		display -o-flex
		display flex
		justify-content space-between
		align-items center
		padding-left 14%
		margin-top 90px
		+mw(1550px)
			padding-left 8%
		+mw(1200px)
			padding-left 6%	
			margin-top 60px
		+mw(768px)
			padding 0 30px
			flex-wrap wrap
			justify-content center
		&__text
			width 50%
			padding-right 17%
			+mw(1550px)
				padding-right 8%
			+mw(1200px)
				padding-right 6%
			+mw(768px)
				padding 0
				width 100%
				margin-bottom 20px
		&:first-child
			margin-top 0
		&:nth-child(2n)
			padding-left 0
			padding-right 14%
			+mw(1550px)
				padding-right 8%
			+mw(1200px)
				padding-right 6%
			+mw(768px)
				padding 0 30px
			.item__text
				padding-left 17%
				padding-right 0
				+mw(1550px)
					padding-left 8%
				+mw(1200px)
					padding-left 6%
				+mw(768px)
					padding 0
					order 1
			img
				+mw(768px)
					order 2
		&__title
			font-size 50px
			margin-bottom 35px
			+mw(1550px)
				font-size 34px
				margin-bottom 20px
			+mw(1200px)
				font-size 30px
				line-height 1.2
			+mw(768px)
				line-height 1
		p
			line-height 30px
			font-size 18px
			+mw(1200px)
				line-height 1.2
		img
			max-height 753px
			width 50%
			+mw(768px)
				width 100%
				max-height 350px
				width auto
				
			