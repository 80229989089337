.error-page
	.page-main__content
		padding 130px	0
		+mw(768px)
			padding 130px 0 0
.page-404
	text-align center
	&__text
		font-size 400px
		line-height 0.8
		color #7ED321
		position relative
		left 0
		bottom 0
		margin 0 auto
		background -webkit-linear-gradient(rgba(126, 211, 33, 1), rgba(126, 211, 33, 0))
		-webkit-background-clip text
		-webkit-text-fill-color transparent
		+mw(1200px)
			font-size 200px
		+mw(500px)
			font-size 150px
	h1
		+mw(900px)
			font-size 40px
		+mw(500px)
			font-size 30px
	&__desk
		font-size 28px
		font-weight lighter
		+mw(500px)
			font-size 26px
	&__links
		width 750px
		margin 60px auto 0
		display -webkit-flex
		display -moz-flex
		display -ms-flex
		display -o-flex
		display flex
		justify-content space-between
		align-items center
		+mw(1200px)
			width 100%
			padding 0 50px
		+mw(768px)
			display block
		a
			text-transform none
			font-size 32px
			&:before
				background-color $color2
			&:hover
				&:before
					background-color transparent
			+mw(768px)
				display block
				font-size 26px
				&:before
					background-color transparent			
				&:hover
					&:before
						background-color transparent
						

@media screen and (max-height: 760px) and (min-width: 760px) {
  .page-404__text {
  	font-size: 200px;
  }
  .page-404__desk {
  	font-size 22px
  }
  
  .page-404__links {
  	margin-top 30px
  }
}