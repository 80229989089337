.page-main__item
	overflow hidden
	+mw(1200px)
		.davids
			display none
		.polygon1
			display none

// hero
.fp-viewing-0
	.header .logo
		display none

.hero
	+mw(1200px)
		min-height 100vh
	+mw(768px)
		min-height auto
	bg()
	.corner-blue
		width 51.5%
		height 100%
		position absolute
		left 0
		top 0
		+mw(1600px)
			width 49%
		+mw(1300px)
			width 47%
		+mw(800px)
			width 85%
		&:after
			content ''
			display block
			width 0
			height 0
			border-bottom 1250px solid transparent
			border-left 1250px solid $bg
			position absolute
			top 0
			right 0

.hero-content
	position relative
	z-index 2
	display flex
	justify-content space-between
	align-items flex-start
	padding-top 46px
	+mw(1600px)
		padding-top 26px
	+mw(768px)
		min-height 50vh
		display block
	&__left
		width 40%
		+mw(1200px)
			img
				visibility hidden
		+mw(768px)
			display none
	&__right
		width 60%
		display flex
		justify-content flex-end
		align-items center
		.btn
			margin-left 35px
			+mw(1600px)
				margin-left 20px
			+mw(768px)
				display none
	.hero-title
		font-size 30px
		font-weight 300
		text-align right
		margin-bottom 0
		+mw(1600px)
			font-size 24px
		+mw(1200px)
			display none
	&__bottom
		display none
		+mw(768px)
			display flex
			flex-direction column
			align-items center
			// margin-bottom 70px
			.hero-title
				display block
				text-align center
				font-size 24px
				font-weight 300
				margin-top 60px
			.btn
				margin-top 35px
	.hero-logo
		width 72.3%
		// max-width 468px
		margin-top -10px
		margin-bottom 60px
		+mw(1600px)
			margin-bottom 50px
	&__nav
		color $color2
		font-size 30px
		+mw(1600px)
			font-size 24px
		+mw(768px)
			display none
		li
			a
				hover($color2)

.hero-image
	position absolute
	left 0
	bottom 0
	+mw(768px)
		max-width 120%
		width 120%
		left -5%
	+mw(600px)
		max-width 140%
		width 140%
		left -12%


// david
.davids
	width 32%
	margin 0 auto
	padding-top 90px
	max-height 100%
	display flex
	justify-content space-between
.david1
	width 45%
	.david__text
			padding-right 40px
			.plus
				right 0
			.line
				margin-left 30px
				+mw(1600px)
					margin-left 15px
.david2
	width 40%
	.david__text
		padding-left 40px
		.plus
			left 0
		.line
			margin-right 30px
			+mw(1600px)
				margin-right 15px
.david
	position relative
	font-size 24px
	+mw(1600px)
		font-size 22px
	&__text
		position absolute
		display flex
		align-items center
		white-space  nowrap	
		.title
			display block
			// hover()
			text-decoration none
			// transition color .3s
			&:hover
				color $color2
		.line
			content ''
			display block
			width 199px
			height 1px	
			background $color2
			+mw(1600px)
				width 100px
		.info
			background-color $color2
			font-size 16px
			line-height 30px
			padding 20px 25px
			position absolute
			left 22px
			top 0
		&.text1
			top 44%
			right 66%
		&.text2
			top 66%
			right 65%
		&.text3
			top 84%
			right 60%
		&.text4
			top 15%
			left 50%
		&.text5
			top 30%
			left 67%
		&.text6
			top 45%
			left 43%
		&.text7
			top 34%
			right 87%
		/*&.text3
			top 15%
			left 50%
		&.text4
			top 30%
			left 68%
			// top 38%
			// left 61%
		&.text5
			top 45%
			left 45%
		&.text6
			top 85%
			right 60%
			// top 89%
			// left  33%*/
		/*&.text5
			top 89%
			left  33%	
		&.text6   
			top 18%
			left 23%
		&.text6   
			top 18%
			left 23%
		&.text7
			top 44%
			left 44%*/

	.plus
		position absolute
		display flex
		align-items center
		justify-content center
		width 41px
		height 41px
		background  $color2
		border 8px solid #fff
		border-radius 50%
		transition border-color .3s
		&:hover
			border-color $color2
		.icon 
			width 14px
			height 14px
			fill #fff

.davids-title
	backTitle()
	// top 12%
	top 8vh
	left -1.65%
	// left -1%

			
// our philosophy
.philosophy-block
	height 100vh
	display flex
	justify-content space-between
	align-items center
	position relative
	+mw(1200px)
		height auto
	+mw(960px)
		flex-direction column
		justify-content center
		padding-top 30px
		padding-bottom 30px
	.btn
		+mw(960px)
			margin 0 auto
		+mw(480px)
			margin 0
	&__img
		width 54%
		align-self flex-end
		text-align center
		padding 0 3.5%
		// padding 0 65px
		+mw(960px)
			width 85%
			align-self center
			padding 0 5%
		+mw(480px)
			padding 0
	.doctors-bg
		width 54%
		height 100%
		position absolute
		// bottom 5%
		bottom 0
		left 0
		z-index -1
		background-repeat no-repeat
		// background-size 130% auto
		background-size 133% auto
		background-position 0% bottom
		+mw(960px)
			width 85%
			height 0
			padding-top 58%
			bottom auto
			top -4%
			left 50%
			transform translate(-50%,0)
			// background-position center bottom
		+mw(480px)
			top 0
	&__text
		width 38.5%
		// width 46%
		margin-right 7.5%
		// padding 90px 7.5% 0
		padding-top 15vh
		// padding-top 90px
		padding-right 8.5%
		// padding-right 7.5%
		padding-left 30px
		font-size 24px
		+mw(1600px)
			font-size 20px
		+mw(1200px)
			padding-top 0
		+mw(960px)
			width 85%
			margin-right 0
			padding 35px 0 0
			font-size 18px
			text-align center
		+mw(480px)
			text-align left
		p
			margin-bottom 25px
			+mw(1600px)
				margin-bottom 21px
			&:last-of-type
				margin-bottom 35px
				+mw(1600px)
					margin-bottom 31px
		.thesis
			font-size 50px
			color $color2
			+mw(1600px)
				font-size 38px
			+mw(1200px)
				font-size 30px
			+mw(960px)
				font-size 24px

.philosophy-title
	backTitle()
	top 12%
	right -0.5%

.line-philosophy1
	line()
	position absolute
	left 50%
	top 0
	z-index -1
	+mw(960px)
		display none
	&:before
		width 418%
		// width 502px
		left -240%
		bottom -240%

.line-philosophy2
	line()
	position absolute
	left auto
	right 0
	top auto
	bottom 30vh
	z-index -1
	+mw(960px)
		display none
	&:before
		width 356%
		// width 428px
		left -100%
		bottom -100%
			


// contacts
.contacts-home
	height 100vh
	display flex
	justify-content space-between
	align-items center
	+mw(1200px)
		height auto
		flex-direction column
		flex-wrap wrap
		align-items center
		justify-content center
.contacts-home
	.address-block
		width 38.5%
		// width 46%
		margin-left 7.5%
		padding 0 7.5%
		+mw(1200px)
			width 100%
			padding 7.5%
			// padding 0 50px
			margin-left 0
			
		/*+mw(768px)
			// padding 0 23px
			.btn
				width 100%*/
		
		&__wrap
			// margin 12% 0
			margin 3.25em 0
			+mw(1600px)
				margin 2em 0
				// margin 10% 0
			+mw(1200px)
				margin-top 0
			+mw(960px)
				max-width 256px

.map-home
	width 54%
	height 60vh
	display flex
	align-items center
	justify-content center
	text-align center
	+mw(1200px)
		width 100%
		margin-top 45px
		height auto
		
		