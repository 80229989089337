.scroll
	.header
		&__content
			.logo
				opacity 0
				visibility hidden
				pointer-events none

.header
	position fixed
	z-index 1000
	top 0
	left 0
	width 100%
	&__content
		padding-top 44px
		+mw(1600px)
			padding-top 24px
			// padding-top 30px
		+mw(768px)
			padding-top 15px
		.logo
			display block
			width 235px
			transition opacity .3s, visibility .3s
			+mw(1600px)
				width 200px
			+mw(480px)
				width 180px

.lang-nav
	font-size 14px
	line-height 1
	text-transform uppercase
	position absolute
	z-index 10
	// right 40px
	right 2%
	top 50px
	margin-right -4px
	&__mob
		display none
		position static
		li
			display inline-block
			margin-right 10px
			&:last-child
				margin-right 0
			&.active
				color $color2
			a
				text-decoration none	
	+mw(1600px)
		top 30px
		// top 35px
	+mw(1200px)
		display none
		&__mob
			display block
			// margin-bottom 15px
			margin-bottom 20px
	&:hover
		.language-dropdown
			opacity 1
			transform none
			pointer-events auto
	.language-dropdown
		position absolute
		top 100%
		left 0
		width 32px
		padding 2px 0
		transform translateY(35px)
		opacity 0
		pointer-events none
		transition .3s
		a
			display block
			padding 8px 0
			text-align center
			text-decoration none
			color $color
			transition color .3s
			&:hover
				hover()
	.item-language-main
		position relative
		cursor default
		span
			display inline-block
			padding 8px 4px

	
.hamburger
	cursor pointer
	position absolute
	z-index 10
	// left 40px
	left 2%
	top 42px
	width 36px
	height 26px
	padding 3px
	margin-top 15px
	margin-left -3px
	+mw(1600px)
		top 22px
		// top 27px
	+mw(768px)
		left auto	
		right 2%
		top 20px
		margin-top 0
	span
		position absolute
		top 12px
		left 3px
		// width 80%
		width 24px
		height 2px
		background $color
		transition background .3s
		transition-delay .3s
	&:before, &:after
		content ""
		position absolute
		left 3px
		height 2px
		background $color
		transition .3s
	&:before
		width 30px
		// width 100%
		top 3px
	&:after
		width 18px
		// width 60%
		bottom 3px


// nav
.top-nav
	position fixed
	top 0
	left 0
	width 100%
	height 100%
	z-index 8
	color $color
	background #384C6C
	opacity 0
	transform translateY(-100%)
	transition opacity .3s , transform .5s cubic-bezier(0.075, 0.82, 0.165, 1)
	&__header
		display flex
		align-items flex-start
		justify-content space-between
		margin-bottom 130px
		padding-top 44px
		+mw(1600px)
			padding-top 24px
		+mw(768px)
			padding-top 15px
	&__logo
		width 230px
		opacity 0
		transition opacity .3s ease .3s
		+mw(1600px)
			width 200px
		+mw(480px)
			width 180px
	/*.btn
		opacity 0
		transition opacity .3s ease .5s*/
	.btn-m
		display none
	+mw(768px)
		.btn
			display none
		.btn-m
			display flex
			// width 100%
			margin-left auto
			margin-right auto
	&__footer
		position absolute
		bottom 0
		left 0
		right 0
		+mw(1200px)
			.footer__content
				padding 25px 0
				.copy
					display none
				.linked
					margin-bottom 20px
					.mob
						margin-top 15px
		+mw(768px)
			.footer__content
				padding 20px 0
				.linked
					font-size 16px
					margin-bottom 15px
					.mob
						margin-top 10px
		+mw(480px)
			.footer__content
				// padding 15px 0
				padding 5px 0 15px
				.linked
					font-size 0

.main-mav 
	display flex 
	align-items flex-start 
	justify-content space-between
	font-size 24px 
	font-weight 300 
	line-height 1.5 
	color #fff 
	+mw(1200px)
		display block
		overflow auto	
		// height 65vh
		// height 75vh
		// height calc(75vh - 118px)
		height calc(100vh - 300px)
		padding-bottom 60px
		&::-webkit-scrollbar-track
			background-color #6082B8

		&::-webkit-scrollbar
			background-color #D7D7D7
			cursor pointer
			width 3px
		&::-webkit-scrollbar-thumb
			background-color #D7D7D7
			cursor pointer
	+mw(768px)
		// height 65vh
		height calc(100vh - 340px)
		padding-bottom 0
	+mw(480px)
		// height calc(100vh - 285px)
		height calc(100vh - 330px)
	a 
		hover() 
		+mw(1200px)
			&:before
				content none
	&__item
		display block 
		margin 0 10px 
		&:first-child 
			margin-left 0 
		&:last-child
			margin-right 0 
		+mw(1200px)
			margin 0
			&.main-m
				&.active
					li
						display block
						a
							span
								top 12px
								transform rotate(135deg)
								
				li
					display none
					&.title
						display block
		+mw(480px)
			&.main-m
				&.active
					li
						a
							span
								top 9px
		&.main-hide
			+mw(768px)
				display none
	.title 
		margin-bottom .9em 
		font-size 1.3333em 
		+mw(480px)
			font-size 20px
		&.title-mob
			display none
			+mw(768px)
				display block
		&.title-hide
			+mw(1200px)
				display none
		a
			span
				display none
			+mw(1200px)
				position relative
				display inline-block
				// padding-right 20px
				span
					display block
					width 10px
					height 10px
					border-top 1px solid $color2
					border-right 1px solid $color2
					position absolute
					transform rotate(45deg)
					right -20px
					top 14px
			+mw(480px)
				span
					top 11px
	.stitle 
		position relative
		margin-bottom .8em 
		font-size 1.17em	
		+mw(480px)
			font-size 18px
		&:before 
			content '' 
			position absolute 
			top .6em 
			left -50px 
			width 35px 
			height 1px 
			background $color2 
	li
		margin-bottom .8em 
		+mw(480px)
			font-size 16px
		&:last-child 
			margin-bottom 0 
			+mw(1200px)
				margin-bottom 0.9em
	&__sub-item  
		padding-left 50px
		


.back-title_nav
	backTitle()
	text-transform uppercase
	top 15%
	left -0.09em
	

// animate
/*.header__container
	transition height .3s
.scroll
	.header__container
		height 65px		*/
		
.openNav
	/*.header__nav
		transform none*/
	.hamburger
		span
			display none
		&:before, &:after	
			top 46%
		&:before
			transform rotate(45deg)
		&:after
			transform rotate(-45deg)
			width 30px
	.top-nav
		transform none
		opacity 1
		transition opacity .2s , transform .5s cubic-bezier(0.075, 0.82, 0.165, 1)
		&__logo
			opacity 1
		/*.btn
			opacity 1*/



body.openNav
	overflow hidden



// media
+mw(1600px)
	.main-mav
		font-size 18px
	.top-nav__header
		margin-bottom 100px

// @media screen and (max-height: 750px)
@media screen and (max-height: 750px)
	.top-nav__header
		margin-bottom 70px
@media screen and (max-height: 650px)
	.top-nav__header
		margin-bottom 30px
	.main-mav 
		li, .stitle, .title
			margin-bottom 10px
			// margin-bottom 0.5em

+mw(1200px)
	.top-nav__header
		margin-bottom 30px
+mw(768px)
	// margin-bottom 45px	
	.ov-h
		overflow-y hidden
		// max-height 70vh
		// max-height calc(100vh - 400px)
		// max-height calc(100vh - 390px)
		max-height calc(100vh - 360px)
		// margin-bottom 10px
		margin-bottom 40px
+mw(480px)
	.ov-h
		overflow-y hidden
		// max-height 48vh
		// max-height calc(100vh - 365px)
		/*max-height calc(100vh - 295px)*/
		max-height calc(100vh - 346px)
		margin-bottom 20px

		