.pager
	padding-top 30px
	padding-bottom 30px
	ul
		display flex
		align-items center
		justify-content center
	.pager-item
		width 25px
		height 25px
		line-height 25px
		text-align center
		margin 0 10px
		+mw(480px)
			margin 0 3px
		a
			display block
			text-decoration none
			transition color .3s ease .15s, transform .15s ease
		&.pager-current a, a:hover
			transform scale(1.375)
			color $color2
	.pager-arr
		width 60px
		height 60px
		+mw(480px)
			width 50px
			height 50px
		a
			display block
			width 100%
			height 100%
			border 1px solid $color2
			position relative
			transition background .3s
			&:hover
				background $color2
			&:before
				content ''
				position absolute
				top 50%
				left 50%
				width 12px
				height 12px
				margin -6px 0 0 -4px
				border-top 2px solid #fff
				border-left 2px solid #fff
		&.pager-arr-next
			margin-left 10px
			+mw(480px)
				margin-left 5px
			a:before
				transform rotate(135deg)
				margin-left -7px
		&.pager-arr-prev
			margin-right 10px
			+mw(480px)
				margin-right 5px
			a:before
				transform rotate(-45deg)