// TOP BLOCK STYLES START
.doctor-info 
	display -webkit-flex
	display -moz-flex
	display -ms-flex
	display -o-flex
	display flex
	justify-content space-between
	align-items center
	padding-top 90px
	position relative
	// background-repeat no-repeat
	// background-size 68%
	// background-position -17% 100%
	+mw(1200px)
		padding-top 30px
	+mw(1080px)
		display block
	+mw(480px)
		padding-top 0
	.line-right 
		position absolute
		right  0
		bottom 0
		display block
		width 300px
		height 300px
		pointer-events none
		+mw(1080px)
			bottom auto
			top 0
		&:before 
			content ''
			display block
			width 420px
			height 1px
			background #7ed321
			-webkit-transform rotate(-45deg)
			-ms-transform rotate(-45deg)
			transform rotate(-45deg)
			-webkit-transform-origin 0 0
			-ms-transform-origin 0 0
			transform-origin 0 0
			position absolute
			bottom 0
			left 0
	&__img
		width 50%
		padding-left 8%
		padding-right 8%
		position relative
		+mw(1600px)
			padding-left 6%
			padding-right 6%
		+mw(1400px)
			padding-left 4%
			padding-right 4%
		+mw(1200px)
			width 40%
		+mw(1080px)
			width 100%
		&:before 
			content ''
			display block
			width 100%
			height 100%
			position absolute
			background-repeat no-repeat
			background-size 115%
			background-position 0 100%
			left 0
			bottom 124px
			background-image url('../img/lorem/Polygon.png')
			z-index 21
			+mw(1080px)
				width 540px
				background-size 161%
				bottom 95px
				background-position -87px 100%
			+mw(800px)
				width 485px
				background-size 161%
				bottom 70px
			+mw(480px)
				width 100%
				bottom 42px
				background-position -57px 100%
	.white-triangle
		position absolute
		left 0
		bottom 0
		background-color #fff
		text-align left
		width 480px
		height 480px
		margin -40px 0 0 -40px
		border-top-right-radius 10%
		-webkit-transform rotate(30deg) skewX(-30deg) scale(1, 0.866)
		-ms-transform rotate(30deg) skewX(-30deg) scale(1, 0.866)
		transform rotate(30deg) skewX(-30deg) scale(1, 0.866)
		&:before,
		&:after
			content ''
			position absolute
			background-color inherit
			width 100%
			height 100%
			border-top-right-radius 10%
		&:before
			transform	rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%)
		&:after
			transform rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%)

	img
		position relative
		z-index 22
		+mw(1080px)
			max-height 500px
		+mw(800px)
			max-height 420px
	&__text
		width 50%
		padding-right 8%
		+mw(1600px)
			padding-right 6%
		+mw(1400px)
			padding-right 4%
		+mw(1200px)
			width 60%
		+mw(1080px)
			width 85%
			margin 60px auto 0
			padding-right 0
		+mw(768px)
			width 100%
			padding 0 15px
		h1
			font-size 50px
			+mw(1600px)
				font-size 40px
			+mw(480px)
				font-size 30px
		p
			font-size 20px
			line-height 30px
			margin-bottom 1em
			+mw(1600px)
				font-size 18px
				line-height 1.4
		h3
			font-size 30px
			font-weight normal
			color $color2
			+mw(768px)
				font-size 24px
		blockquote
			position relative
			padding-left 50px
			margin 20px 0 0
			text-align right
			font-style italic
			&:before
				content ''
				display block
				width 1px
				height 100%
				background $color2
				position absolute
				left 0
				top 0
// TOP BLOCK STYLES END


// BOTTOM BLOCK STYLES START
.doctors-all
	margin-top 150px
	position relative
	+mw(1080px)
		margin-top 60px
	.triangle2
		bottom 4%
	.line-right 
		position absolute
		left -35px
		top 16px
		display block
		width 150px
		height 150px
		pointer-events none
		&:before 
			content ''
			display block
			width 220px
			height 1px
			background #7ed321
			-webkit-transform rotate(-45deg)
			-ms-transform rotate(-45deg)
			transform rotate(-45deg)
			-webkit-transform-origin 0 0
			-ms-transform-origin 0 0
			transform-origin 0 0
			position absolute
			bottom 0
			left 0
	&__inner
		font-size 0
		line-height 0
		letter-spacing 0
		padding-top 35px
		margin-bottom 80px
		margin-right -40px
		overflow hidden
		+mw(1100px)
			padding-top 0
			margin-right -25px
			margin-bottom 60px
	.pager
		font-size 16px
		margin-top 85px
		+mw(1100px)
			margin-top 50px
	.doctor
		margin-top 55px 
		width 33.3%
		text-align left
		display inline-block
		padding-right 40px
		// position relative
		// z-index 2
		text-decoration none
		transition opacity .3s ease
		vertical-align top
		+mw(1100px)
			padding-right 25px
		+mw(768px)
			width 50%
		+mw(480px)
			width 100%
			margin-top 40px
		&:hover
			opacity .8
		&:nth-child(3n)
			position relative
			&:after 
				content ''
				display block
				width 700px
				height 700px
				background-image url(../img/svg/Polygon.svg)
				background-repeat no-repeat
				background-position center center
				background-size contain
				position absolute
				right 79%
				top 35%
				transform rotate(19deg)
				+mw(1400px)
					width 400px
					height 400px
		&:nth-child(6n)
			&:after 
				content none
				// transform rotate(-82deg)
				// bottom 25px
				// top auto
		&:nth-child(9n)
			&:after  
				transform rotate(-25deg)
				bottom 50px
				top auto
		&__img
			height 700px
			width 100%
			display block
			background-position top center
			background-size cover
			background-repeat no-repeat
			+mw(1600px)
				height 550px
			+mw(1400px)
				height 450px
			+mw(1100px)
				height 380px
		&__name
			margin 20px auto 0
			font-size 24px
			line-height 1
			font-weight normal
			text-align left
		&__position
			font-size 18px
			margin-top 15px
			line-height 1
			display inline-block
// BOTTOM BLOCK STYLES END


// TABS STYLES START
.tabs-nav
	overflow visible
	
	.btns
		// border-bottom 1px solid #fff
		display -webkit-flex
		display -moz-flex
		display -ms-flex
		display -o-flex
		display flex
		justify-content space-between
		align-items center
		position relative
		overflow visible
		+mw(1200px)
			display block
			text-align center
			max-width 400px
			margin auto
		&:after
			content ''
			display block
			position absolute
			left 0
			bottom 0
			width 100%
			height 1px
			background-color #fff
			+mw(1200px)
				content none
	.content
		&__item
			display none
			
			&.active
				display block
	&__btn
		font-size 21px
		line-height 26px
		text-transform uppercase
		padding-bottom 19px
		cursor pointer
		transition all .3s ease
		position relative
		cursor pointer
		border-bottom 1px solid #fff
		+mw(1200px)
			padding-bottom 10px
			margin-bottom 10px	
		&:after
			content ''
			display block
			position absolute
			width 100%
			left 0
			bottom -1px
			height 4px
			z-index 3
			background-color transparent
			transition all .3s ease
		&.active
			color #7ED321
			&:after
				background-color #7ED321
				
		&:hover
			color #7ED321
			&:after
				background-color #7ED321
			
			
			
// TABS STYLES END
		