.home , .error-page
	.footer
		position absolute
		bottom 0
		left 0
		right 0
		+mw(1200px)
			position static
	
.footer
	&__content
		padding 70px 0
		flex()
		font-size 12px
		position relative
		+mw(1600px)
			padding 40px 0
		+mw(1200px)
			flex-wrap wrap
			align-items center
			position static
			.sun, .linked, .copy
				width 100%
				text-align center
			.copy
				order 1
				margin-bottom 35px
			.sun
				order 2
		.sun
			hover()
			span
				color $color2
			+mw(1200px)
				margin-left 0
		.linked
			display block
			font-size 20px
			color $color2
			white-space nowrap
			+mw(1600px)
				font-size 18px
			+mw(1200px)
				margin-bottom 35px
				white-space normal
				&:before
					display none
			+mw(1200px)
				svg, span
					display none
			.mob
				display none
				margin-top 25px
				+mw(1200px)
					display flex
					justify-content center
				.youtube, .facebook, .insta
					margin 0 12px
					&:before
						display none
					svg
						display block
						position static
						transform none
			a
				hover($color2)						
				&.youtube
					margin-left 60px
					+mw(1600px)
						margin-left 50px
						+mw(1200px)
							margin 0
					svg
						width 50px
						left -56px
						transform translate(0,-50%)
						+mw(1600px)
							width 40px
							left -47px
				&.facebook
					margin-left 50px
					+mw(1600px)
						margin-left 42px
					+mw(1200px)
						margin 0
					svg
						width 37px
						left -44px
						transform translate(0,-52%)
						+mw(1600px)
							width 32px
							left -39px
				&.insta
					margin-left 50px
					+mw(1600px)
						margin-left 42px
					+mw(1200px)
						margin 0
					svg
						width 37px
						left -42px
						transform translate(0,-52%)
						+mw(1600px)
							width 32px
							left -37px
						+mw(1200px)
							margin-top -2px
				
			svg
				position absolute
				left 50%
				top 50%
				transform translate(50%,-50%)

