// Fonts mixin
font-url(file)
  return '../fonts/' + file

webfont(family, file, weight = 'normal',  hack-chrome-windows = false)
  @font-face
    font-family family
    src url(font-url(file + '.woff2')) format('woff2'), 
        url(font-url(file + '.woff')) format('woff'), 
        url(font-url(file + '.ttf')) format('truetype'), 
        url(font-url(file + '.svg#'+ family)) format('svg')
    font-weight weight
    font-style normal
  if hack-chrome-windows
    @media screen and (-webkit-min-device-pixel-ratio:0)
      @font-face
        font-family family
        src url(font-url(file + '.svg#'+ family)) format('svg')
        


webfont('ProximaNova', 'ProximaNova/regular/ProximaNova-Regular', weight = 400)
webfont('ProximaNova', 'ProximaNova/bold/ProximaNova-Bold', weight = 700)
webfont('ProximaNova', 'ProximaNova/light/ProximaNova-Light', weight = 300)