.bottom-block
	position relative
	// margin 5% 7.5%
	margin 95px 7.5%
	+mw(800px)
		margin 60px 7.5% 40px
	+mw(480px)
		margin 60px 0 40px
	.address-block
		margin 0 7.5%
		padding 0 7.5%
		display flex
		justify-content space-between
		align-items center
		border 5px solid rgba(#fff,0.04)
		+mw(1600px)
			padding 0 5%
		+mw(1200px)
			margin 0 0.5%
		+mw(768px)
			border none
			align-items center
			justify-content center
		&__wrap
			margin 2.75em 0 1.75em
			+mw(1600px)
				margin 2em 0 1em
			+mw(768px)
				display none
	.line-left
		position absolute
		left -9.2%
		top -8%
		line()
		+mw(800px)
			display none
	.line-right
		position absolute
		right -10%
		bottom -40%
		line()
		+mw(800px)
			display none

.address-block
	font-size 20px
	+mw(1600px)
		font-size 18px
	p
		margin-bottom 20px
	.tel, .email, .address
		font-size 30px
		line-height 1.3333
		+mw(1600px)
			font-size 24px
	.tel, .address-map
		hover()
	.email
		color $color2
		hover($color2)
	.title
		margin-bottom 7px