.gallery
	&__row
		padding-top 230px
		display flex
		-webkit-justify-content space-between
		justify-content space-between
		-webkit-align-items center
		align-items flex-start
		+mw(1200px)
			padding-top 30px
		+mw(480px)
			display block
	&__col
		width 48%
		position relative
		&:last-child
			margin-top 200px
			+mw(1200px)
				margin-top 150px
			+mw(768px)
				margin-top 95px
			+mw(480px)
				margin-top 0
			.double-line
				top auto
				left auto
				right 60%
				bottom 0
				z-index 12
				+mw(1300px)
					&:before
						content none
			.gallery__item
				&:after
					position absolute
					z-index 9
		+mw(480px)
			width 100%
		&:first-child
			.gallery__item
				&:after
					content ''
					display block
					width 800px
					height 800px
					background-image url(../img/svg/Polygon.svg)
					background-repeat no-repeat
					background-position center center
					background-size contain
					position absolute
					left 57%
					top 13%
					transform rotate(19deg)
					z-index -1
					+mw(1500px)
						width 400px
						height 400px
					+mw(1200px)
						width 300px
						height 300px
					+mw(800px)
							content none
				&:nth-child(2n+1)
					&:after
						transform rotate(-75deg)
						bottom 85px
						left 85%
						top auto
						+mw(800px)
							content none
					&:before
						content ''
						top 35%
						right -17%
						+mw(800px)
							content none
			.double-line
				top 11%
				left auto
				right -54%
				z-index 12
				+mw(1300px)
					top 13%
					&:before
						width 100%
						left 25%
						top 74%
					&:after
						width 61.3%
	&__title
		font-size 50px
		line-height 45px
		margin-bottom 55px
		+mw(1440px)
			font-size 40px
			line-height 1
		+mw(1200px)
			font-size 35px
		+mw(768px)
			margin-bottom 30px
			font-size 30px
	&__item
		text-decoration none
		display inline-block
		margin-bottom 100px
		transition opacity .3s ease
		position relative
		z-index 11
		+mw(1440px)
			margin-bottom 80px
		+mw(1200px)
			margin-bottom 60px
		+mw(768px)
			margin-bottom 30px
		&:hover
			opacity .8
		&:before
			content none
			display block
			width 200px
			height 1px
			background #7ed321
			-webkit-transform rotate(45deg)
			-ms-transform rotate(45deg)
			transform rotate(45deg)
			-webkit-transform-origin 0 0
			-ms-transform-origin 0 0
			transform-origin 0 0
			position absolute
			+mw(800px)
				content none
