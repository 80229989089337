//  == media mixin
mw($media = 1700px, $above = false)
	if($above)
		@media (min-width $media)
			{block}
	else
		@media (max-width $media)
			{block}
			
mh($media = 600px, $above = false)
	if($above)
		@media (min-height $media)
			{block}
	else
		@media (max-height $media)
			{block}

// == other
		

bg()
	background-size cover
	background-position center center
	background-repeat no-repeat

flex()
	display flex
	justify-content space-between
	align-items center


hover($color=#fff, $pos=relative)
	text-decoration none
	position $pos
	&:hover:before
		background $color
	&:before
		content ''
		display block
		width 100%
		height 1px
		background transparent
		opacity .7
		position absolute
		left 0
		bottom 0
		transition background .3s

page-title()
	width 50%
	font-size 50px
	font-weight 300
	line-height 1
	display flex
	flex-direction column
	position absolute
	left 7.5%
	top 20vh
	// top 20%
	z-index 20
	+mw(1600px)
		font-size 38px
		top 18vh
		// top 18%
	+mw(1200px)
		font-size 28px
		width 85%
		// width 100%
		// top 150px
		position relative
		top auto
		// padding-top 70px
	.green
		color $color2
		text-transform uppercase
		margin-left 40px
		+mw(800px)
			margin-left 20px
	&:before
		content ''
		display block
		width 170px
		height 1px
		background $color2
		transform rotate(-45deg)
		transform-origin 0 0
		position absolute
		bottom 5px
		left -15.1%
		+mw(1600px)
			width 130px
		+mw(1200px)
			left -25%
			bottom -35px
		/*+mw(768px)
			bottom -25px
			left -9%*/

btn()
	display flex
	justify-content center
	align-items center
	font-size 24px
	text-align center
	color $text
	// width 400px
	height 90px
	border-radius 5px
	background $color2
	text-decoration none
	line-height 1
	transition .3s
	&:hover
		background #fff
	&.disable, &:disabled
		background rgba(#7ED321,0.4)
		cursor default
	+mw(1600px)
		font-size 18px
		// width 300px
		height 60px
	+mw(768px)
		height 55px

btn-fixed()
	position fixed
	left -5px
	top calc(50vh + 115px)
	z-index 900
	transform rotate(-90deg)
	transform-origin 0 0
	display flex
	justify-content center
	align-items center
	font-size 24px
	text-align center
	color $text
	width 231px
	height 71px
	border-radius 5px
	background $color2
	text-decoration none
	line-height 1
	transition .3s
	&:hover
		background #fff
	&.disable
		background rgba(#7ED321,0.4)
	+mw(1600px)
		font-size 18px
		width 170px
		height 50px

backTitle($color = #fff, $fz = 300px)
	position absolute
	font-size $fz
	color $color
	display block
	z-index -1
	letter-spacing -.05em	
	opacity .05
	pointer-events none
	+mw(1600px)
		font-size 200px
	+mw(1200px)
		display none
		
allBtn()
	display flex
	align-items center
	color $color2
	text-decoration none
	font-size 20px
	line-height 1.5
	hover($color2)
	/*&:hover
		color #fff
		.icon
			fill #fff*/
	.icon
		margin-left 20px
		width 27px
		height 20px
		fill $color2

line()
	display block
	width 120px
	height 120px
	pointer-events none
	+mw(1600px)
		width 92px
		height 92px
	&:before
		content ''
		display block
		width 170px
		height 1px
		background $color2
		transform rotate(-45deg)
		transform-origin 0 0
		position absolute
		bottom 0
		left 0
		+mw(1600px)
			width 130px

double-line()
	display block
	width 442px
	height 420px
	position absolute
	left 46%
	top 60%
	transform translate(-50%,-50%)
	pointer-events none
	+mw(1600px)
		width 342px
		height 325px
		left 49%
	+mw(800px)
		display none
	&:before, &:after
		content ''
		background $color2
		position absolute
	&:before
		width 129.86%
		// width 574px
		height 1px
		left 0
		top 100%
		transform rotate(-45deg)
		transform-origin 0 0
	&:after
		top 0
		right 0
		width 73.3%
		// width 324px
		height 3px
		transform rotate(-45deg)
		transform-origin 100% 0

arr()
	width 80px
	height 80px
	border-top 1px solid $color2
	border-left 1px solid $color2
	// transform rotate(-45deg)
	transition all .3s ease
	+mw(1600px)
		width 60px
		height 60px
	&:after 
		content ''
		position absolute
		width 65px
		height 65px
		border-top 1px solid $color2
		border-left 1px solid $color2
		right 7px
		top 7px
		transition all .3s ease
		+mw(1600px)
			width 45px
			height 45px
		+mw(1200px)
			width 25px
			height 25px
			right -1px
			top 5px
	&:hover
		border-color $color
		&:after 
			border-color $color

polygon()
	display block
	width 42.5%
	// width 816px
	height 0
	padding-top 48.90625%
	// padding-top 939px
	// height 939px
	pointer-events none
	&:before
		content ''
		display block
		width 100%
		height 100%
		background-image url(../img/svg/Polygon.svg)
		background-repeat no-repeat
		background-position center center
		background-size cover
		// transform rotate(103.53deg)
		position absolute
		left 0
		top 0




customScroll($w = 3px, $bgt = #6082B8, $b = 0px, $bg = #D7D7D7 )
	overflow auto

	&::-webkit-scrollbar
		background-color transparent
		cursor pointer
		width $w
		
	&::-webkit-scrollbar-track
		background-color $bgt
		border $b
		
	&::-webkit-scrollbar-thumb
		background-color $bg
		cursor pointer
	
	-ms-overflow-style -ms-autohiding-scrollbar	
	
	/*@media (min-width:1241px)*/