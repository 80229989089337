@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova/regular/ProximaNova-Regular.woff2") format('woff2'), url("../fonts/ProximaNova/regular/ProximaNova-Regular.woff") format('woff'), url("../fonts/ProximaNova/regular/ProximaNova-Regular.ttf") format('truetype'), url("../fonts/ProximaNova/regular/ProximaNova-Regular.svg#ProximaNova") format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova/bold/ProximaNova-Bold.woff2") format('woff2'), url("../fonts/ProximaNova/bold/ProximaNova-Bold.woff") format('woff'), url("../fonts/ProximaNova/bold/ProximaNova-Bold.ttf") format('truetype'), url("../fonts/ProximaNova/bold/ProximaNova-Bold.svg#ProximaNova") format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova/light/ProximaNova-Light.woff2") format('woff2'), url("../fonts/ProximaNova/light/ProximaNova-Light.woff") format('woff'), url("../fonts/ProximaNova/light/ProximaNova-Light.ttf") format('truetype'), url("../fonts/ProximaNova/light/ProximaNova-Light.svg#ProximaNova") format('svg');
  font-weight: 300;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
p {
  margin: 0;
}
a {
  color: inherit;
}
.logo img {
  display: block;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
  max-width: 100%;
}
textarea {
  resize: none;
}
section {
  position: relative;
}
input,
select,
button {
  outline: none;
}
[type='submit'] {
  cursor: pointer;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
@media (min-width: 1101px) {
  a,
  [type=submit] {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}
/*site styles*/
html {
  font-size: 16px;
  min-height: 100%;
  line-height: 1.2;
}
body {
  font-family: 'ProximaNova', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
  color: #fff;
  background: #384c6c;
  background: #384c6c;
}
body.home {
  background: #384c6c;
  background: -moz-linear-gradient(top, #384c6c 0%, #384c6c 28%, #5f82b8 52%, #384c6c 76%, #384c6c 100%);
  background: -webkit-linear-gradient(top, #384c6c 0%, #384c6c 28%, #5f82b8 52%, #384c6c 76%, #384c6c 100%);
  background: linear-gradient(to bottom, #384c6c 0%, #384c6c 28%, #5f82b8 52%, #384c6c 76%, #384c6c 100%);
}
h1 {
  font-size: 50px;
  font-weight: lighter;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh);
}
.page-main {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}
.page-main__content {
  padding-top: 20vh;
}
@media (max-width: 1600px) {
  .page-main__content {
    padding-top: 18vh;
  }
}
@media (max-width: 1200px) {
  .page-main__content {
    padding-top: 160px;
  }
}
@media (max-width: 768px) {
  .page-main__content {
    padding-top: 100px;
  }
}
.container {
  width: 85%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
  color: #20272f;
  height: 90px;
  border-radius: 5px;
  background: #7ed321;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
  width: 400px;
}
.btn:hover {
  background: #fff;
}
.btn.disable,
.btn:disabled {
  background: rgba(126,211,33,0.4);
  cursor: default;
}
@media (max-width: 1600px) {
  .btn {
    font-size: 18px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .btn {
    height: 55px;
  }
}
@media (max-width: 1600px) {
  .btn {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .btn {
    width: 290px;
  }
}
@media (max-width: 480px) {
  .btn {
    width: 270px;
  }
}
.btn-fixed {
  position: fixed;
  left: -5px;
  top: calc(50vh + 115px);
  z-index: 900;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
  color: #20272f;
  width: 231px;
  height: 71px;
  border-radius: 5px;
  background: #7ed321;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}
.btn-fixed:hover {
  background: #fff;
}
.btn-fixed.disable {
  background: rgba(126,211,33,0.4);
}
@media (max-width: 1600px) {
  .btn-fixed {
    font-size: 18px;
    width: 170px;
    height: 50px;
  }
}
@media (max-width: 800px) {
  .btn-fixed {
    display: none;
  }
}
.btn-fixed span {
  position: relative;
  z-index: 10;
}
.btn-fixed:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  pointer-events: none;
  animation: btn-blink 6s ease infinite;
}
.btn-fixed:hover:before {
  animation: none;
}
.btn-virtual {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
  color: #20272f;
  height: 90px;
  border-radius: 5px;
  background: #7ed321;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
  position: absolute;
  left: calc(50% - 165px - 110px);
  bottom: 45px;
  z-index: 100;
  width: 660px;
}
.btn-virtual:hover {
  background: #fff;
}
.btn-virtual.disable,
.btn-virtual:disabled {
  background: rgba(126,211,33,0.4);
  cursor: default;
}
@media (max-width: 1600px) {
  .btn-virtual {
    font-size: 18px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .btn-virtual {
    height: 55px;
  }
}
@media (max-width: 1600px) {
  .btn-virtual {
    width: 480px;
    left: calc(50% - 60px - 110px);
  }
}
@media (max-width: 1200px) {
  .btn-virtual {
    bottom: 45px;
    left: calc(50% - 150px - 50px);
  }
}
@media (max-width: 800px) {
  .btn-virtual {
    bottom: 70px;
    left: calc(50% - 240px);
  }
}
@media (max-width: 600px) {
  .btn-virtual {
    width: 300px;
    padding: 0 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .btn-virtual {
    width: 270px;
  }
}
.btn-virtual .icon {
  width: 48px;
  height: 35px;
  margin-left: 25px;
}
@media (max-width: 1600px) {
  .btn-virtual .icon {
    margin-left: 15px;
    width: 34px;
    height: 30px;
  }
}
@media (max-width: 1600px) and (max-width: 600px) {
  .btn-virtual .icon {
    margin-left: 5px;
  }
}
.section-title,
.page-title {
  width: 50%;
  font-size: 50px;
  font-weight: 300;
  line-height: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 7.5%;
  top: 20vh;
  z-index: 20;
}
@media (max-width: 1600px) {
  .section-title,
  .page-title {
    font-size: 38px;
    top: 18vh;
  }
}
@media (max-width: 1200px) {
  .section-title,
  .page-title {
    font-size: 28px;
    width: 85%;
    position: relative;
    top: auto;
  }
}
.section-title .green,
.page-title .green {
  color: #7ed321;
  text-transform: uppercase;
  margin-left: 40px;
}
@media (max-width: 800px) {
  .section-title .green,
  .page-title .green {
    margin-left: 20px;
  }
}
.section-title:before,
.page-title:before {
  content: '';
  display: block;
  width: 170px;
  height: 1px;
  background: #7ed321;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 5px;
  left: -15.1%;
/*+mw(768px)
			bottom -25px
			left -9%*/
}
@media (max-width: 1600px) {
  .section-title:before,
  .page-title:before {
    width: 130px;
  }
}
@media (max-width: 1200px) {
  .section-title:before,
  .page-title:before {
    left: -25%;
    bottom: -35px;
  }
}
@media (max-width: 1200px) {
  .section-title {
    padding-top: 70px;
  }
}
@media (max-width: 768px) {
  .page-title {
    padding-top: 50px;
  }
}
.page-title h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 50px;
}
@media (max-width: 1600px) {
  .page-title h1 {
    font-size: 38px;
  }
}
@media (max-width: 1200px) {
  .page-title h1 {
    font-size: 28px;
  }
}
.page-title .a-date {
  font-size: 16px;
  margin-left: 0;
}
.back-title {
  position: absolute;
  font-size: 300px;
  color: #fff;
  display: block;
  z-index: -1;
  letter-spacing: -0.05em;
  opacity: 0.05;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .back-title {
    font-size: 200px;
  }
}
@media (max-width: 1200px) {
  .back-title {
    display: none;
  }
}
.double-line {
  display: block;
  width: 442px;
  height: 420px;
  position: absolute;
  left: 46%;
  top: 60%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
@media (max-width: 1600px) {
  .double-line {
    width: 342px;
    height: 325px;
    left: 49%;
  }
}
@media (max-width: 800px) {
  .double-line {
    display: none;
  }
}
.double-line:before,
.double-line:after {
  content: '';
  background: #7ed321;
  position: absolute;
}
.double-line:before {
  width: 129.86%;
  height: 1px;
  left: 0;
  top: 100%;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.double-line:after {
  top: 0;
  right: 0;
  width: 73.3%;
  height: 3px;
  transform: rotate(-45deg);
  transform-origin: 100% 0;
}
.more {
  display: inline-block;
  font-size: 18px;
  color: #7ed321;
  text-transform: uppercase;
  line-height: 1.5;
  text-decoration: none;
  position: relative;
}
.more:hover:before {
  background: #7ed321;
}
.more:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.scroll .header__content .logo {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
}
.header__content {
  padding-top: 44px;
}
@media (max-width: 1600px) {
  .header__content {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .header__content {
    padding-top: 15px;
  }
}
.header__content .logo {
  display: block;
  width: 235px;
  transition: opacity 0.3s, visibility 0.3s;
}
@media (max-width: 1600px) {
  .header__content .logo {
    width: 200px;
  }
}
@media (max-width: 480px) {
  .header__content .logo {
    width: 180px;
  }
}
.lang-nav {
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  z-index: 10;
  right: 2%;
  top: 50px;
  margin-right: -4px;
}
.lang-nav__mob {
  display: none;
  position: static;
}
.lang-nav__mob li {
  display: inline-block;
  margin-right: 10px;
}
.lang-nav__mob li:last-child {
  margin-right: 0;
}
.lang-nav__mob li.active {
  color: #7ed321;
}
.lang-nav__mob li a {
  text-decoration: none;
}
@media (max-width: 1600px) {
  .lang-nav {
    top: 30px;
  }
}
@media (max-width: 1200px) {
  .lang-nav {
    display: none;
  }
  .lang-nav__mob {
    display: block;
    margin-bottom: 20px;
  }
}
.lang-nav:hover .language-dropdown {
  opacity: 1;
  transform: none;
  pointer-events: auto;
}
.lang-nav .language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 32px;
  padding: 2px 0;
  transform: translateY(35px);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.lang-nav .language-dropdown a {
  display: block;
  padding: 8px 0;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}
.lang-nav .language-dropdown a:hover {
  text-decoration: none;
  position: relative;
}
.lang-nav .language-dropdown a:hover:hover:before {
  background: #fff;
}
.lang-nav .language-dropdown a:hover:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.lang-nav .item-language-main {
  position: relative;
  cursor: default;
}
.lang-nav .item-language-main span {
  display: inline-block;
  padding: 8px 4px;
}
.hamburger {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  left: 2%;
  top: 42px;
  width: 36px;
  height: 26px;
  padding: 3px;
  margin-top: 15px;
  margin-left: -3px;
}
@media (max-width: 1600px) {
  .hamburger {
    top: 22px;
  }
}
@media (max-width: 768px) {
  .hamburger {
    left: auto;
    right: 2%;
    top: 20px;
    margin-top: 0;
  }
}
.hamburger span {
  position: absolute;
  top: 12px;
  left: 3px;
  width: 24px;
  height: 2px;
  background: #fff;
  transition: background 0.3s;
  transition-delay: 0.3s;
}
.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  left: 3px;
  height: 2px;
  background: #fff;
  transition: 0.3s;
}
.hamburger:before {
  width: 30px;
  top: 3px;
}
.hamburger:after {
  width: 18px;
  bottom: 3px;
}
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  color: #fff;
  background: #384c6c;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
/*.btn
		opacity 0
		transition opacity .3s ease .5s*/
}
.top-nav__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 130px;
  padding-top: 44px;
}
@media (max-width: 1600px) {
  .top-nav__header {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .top-nav__header {
    padding-top: 15px;
  }
}
.top-nav__logo {
  width: 230px;
  opacity: 0;
  transition: opacity 0.3s ease 0.3s;
}
@media (max-width: 1600px) {
  .top-nav__logo {
    width: 200px;
  }
}
@media (max-width: 480px) {
  .top-nav__logo {
    width: 180px;
  }
}
.top-nav .btn-m {
  display: none;
}
@media (max-width: 768px) {
  .top-nav .btn {
    display: none;
  }
  .top-nav .btn-m {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}
.top-nav__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 1200px) {
  .top-nav__footer .footer__content {
    padding: 25px 0;
  }
  .top-nav__footer .footer__content .copy {
    display: none;
  }
  .top-nav__footer .footer__content .linked {
    margin-bottom: 20px;
  }
  .top-nav__footer .footer__content .linked .mob {
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  .top-nav__footer .footer__content {
    padding: 20px 0;
  }
  .top-nav__footer .footer__content .linked {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .top-nav__footer .footer__content .linked .mob {
    margin-top: 10px;
  }
}
@media (max-width: 480px) {
  .top-nav__footer .footer__content {
    padding: 5px 0 15px;
  }
  .top-nav__footer .footer__content .linked {
    font-size: 0;
  }
}
.main-mav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
}
@media (max-width: 1200px) {
  .main-mav {
    display: block;
    overflow: auto;
    height: calc(100vh - 300px);
    padding-bottom: 60px;
  }
  .main-mav::-webkit-scrollbar-track {
    background-color: #6082b8;
  }
  .main-mav::-webkit-scrollbar {
    background-color: #d7d7d7;
    cursor: pointer;
    width: 3px;
  }
  .main-mav::-webkit-scrollbar-thumb {
    background-color: #d7d7d7;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .main-mav {
    height: calc(100vh - 340px);
    padding-bottom: 0;
  }
}
@media (max-width: 480px) {
  .main-mav {
    height: calc(100vh - 330px);
  }
}
.main-mav a {
  text-decoration: none;
  position: relative;
}
.main-mav a:hover:before {
  background: #fff;
}
.main-mav a:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
@media (max-width: 1200px) {
  .main-mav a:before {
    content: none;
  }
}
.main-mav__item {
  display: block;
  margin: 0 10px;
}
.main-mav__item:first-child {
  margin-left: 0;
}
.main-mav__item:last-child {
  margin-right: 0;
}
@media (max-width: 1200px) {
  .main-mav__item {
    margin: 0;
  }
  .main-mav__item.main-m.active li {
    display: block;
  }
  .main-mav__item.main-m.active li a span {
    top: 12px;
    transform: rotate(135deg);
  }
  .main-mav__item.main-m li {
    display: none;
  }
  .main-mav__item.main-m li.title {
    display: block;
  }
}
@media (max-width: 480px) {
  .main-mav__item.main-m.active li a span {
    top: 9px;
  }
}
@media (max-width: 768px) {
  .main-mav__item.main-hide {
    display: none;
  }
}
.main-mav .title {
  margin-bottom: 0.9em;
  font-size: 1.3333em;
}
@media (max-width: 480px) {
  .main-mav .title {
    font-size: 20px;
  }
}
.main-mav .title.title-mob {
  display: none;
}
@media (max-width: 768px) {
  .main-mav .title.title-mob {
    display: block;
  }
}
@media (max-width: 1200px) {
  .main-mav .title.title-hide {
    display: none;
  }
}
.main-mav .title a span {
  display: none;
}
@media (max-width: 1200px) {
  .main-mav .title a {
    position: relative;
    display: inline-block;
  }
  .main-mav .title a span {
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid #7ed321;
    border-right: 1px solid #7ed321;
    position: absolute;
    transform: rotate(45deg);
    right: -20px;
    top: 14px;
  }
}
@media (max-width: 480px) {
  .main-mav .title a span {
    top: 11px;
  }
}
.main-mav .stitle {
  position: relative;
  margin-bottom: 0.8em;
  font-size: 1.17em;
}
@media (max-width: 480px) {
  .main-mav .stitle {
    font-size: 18px;
  }
}
.main-mav .stitle:before {
  content: '';
  position: absolute;
  top: 0.6em;
  left: -50px;
  width: 35px;
  height: 1px;
  background: #7ed321;
}
.main-mav li {
  margin-bottom: 0.8em;
}
@media (max-width: 480px) {
  .main-mav li {
    font-size: 16px;
  }
}
.main-mav li:last-child {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .main-mav li:last-child {
    margin-bottom: 0.9em;
  }
}
.main-mav__sub-item {
  padding-left: 50px;
}
.back-title_nav {
  position: absolute;
  font-size: 300px;
  color: #fff;
  display: block;
  z-index: -1;
  letter-spacing: -0.05em;
  opacity: 0.05;
  pointer-events: none;
  text-transform: uppercase;
  top: 15%;
  left: -0.09em;
}
@media (max-width: 1600px) {
  .back-title_nav {
    font-size: 200px;
  }
}
@media (max-width: 1200px) {
  .back-title_nav {
    display: none;
  }
}
.openNav .hamburger span {
  display: none;
}
.openNav .hamburger:before,
.openNav .hamburger:after {
  top: 46%;
}
.openNav .hamburger:before {
  transform: rotate(45deg);
}
.openNav .hamburger:after {
  transform: rotate(-45deg);
  width: 30px;
}
.openNav .top-nav {
  transform: none;
  opacity: 1;
  transition: opacity 0.2s, transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
/*.btn
			opacity 1*/
}
.openNav .top-nav__logo {
  opacity: 1;
}
body.openNav {
  overflow: hidden;
}
@media (max-width: 1600px) {
  .main-mav {
    font-size: 18px;
  }
  .top-nav__header {
    margin-bottom: 100px;
  }
}
@media screen and (max-height: 750px) {
  .top-nav__header {
    margin-bottom: 70px;
  }
}
@media screen and (max-height: 650px) {
  .top-nav__header {
    margin-bottom: 30px;
  }
  .main-mav li,
  .main-mav .stitle,
  .main-mav .title {
    margin-bottom: 10px;
  }
}
@media (max-width: 1200px) {
  .top-nav__header {
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .ov-h {
    overflow-y: hidden;
    max-height: calc(100vh - 360px);
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .ov-h {
    overflow-y: hidden;
/*max-height calc(100vh - 295px)*/
    max-height: calc(100vh - 346px);
    margin-bottom: 20px;
  }
}
.home .footer,
.error-page .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 1200px) {
  .home .footer,
  .error-page .footer {
    position: static;
  }
}
.footer__content {
  padding: 70px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: relative;
}
@media (max-width: 1600px) {
  .footer__content {
    padding: 40px 0;
  }
}
@media (max-width: 1200px) {
  .footer__content {
    flex-wrap: wrap;
    align-items: center;
    position: static;
  }
  .footer__content .sun,
  .footer__content .linked,
  .footer__content .copy {
    width: 100%;
    text-align: center;
  }
  .footer__content .copy {
    order: 1;
    margin-bottom: 35px;
  }
  .footer__content .sun {
    order: 2;
  }
}
.footer__content .sun {
  text-decoration: none;
  position: relative;
}
.footer__content .sun:hover:before {
  background: #fff;
}
.footer__content .sun:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.footer__content .sun span {
  color: #7ed321;
}
@media (max-width: 1200px) {
  .footer__content .sun {
    margin-left: 0;
  }
}
.footer__content .linked {
  display: block;
  font-size: 20px;
  color: #7ed321;
  white-space: nowrap;
}
@media (max-width: 1600px) {
  .footer__content .linked {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .footer__content .linked {
    margin-bottom: 35px;
    white-space: normal;
  }
  .footer__content .linked:before {
    display: none;
  }
}
@media (max-width: 1200px) {
  .footer__content .linked svg,
  .footer__content .linked span {
    display: none;
  }
}
.footer__content .linked .mob {
  display: none;
  margin-top: 25px;
}
@media (max-width: 1200px) {
  .footer__content .linked .mob {
    display: flex;
    justify-content: center;
  }
}
.footer__content .linked .mob .youtube,
.footer__content .linked .mob .facebook,
.footer__content .linked .mob .insta {
  margin: 0 12px;
}
.footer__content .linked .mob .youtube:before,
.footer__content .linked .mob .facebook:before,
.footer__content .linked .mob .insta:before {
  display: none;
}
.footer__content .linked .mob .youtube svg,
.footer__content .linked .mob .facebook svg,
.footer__content .linked .mob .insta svg {
  display: block;
  position: static;
  transform: none;
}
.footer__content .linked a {
  text-decoration: none;
  position: relative;
}
.footer__content .linked a:hover:before {
  background: #7ed321;
}
.footer__content .linked a:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.footer__content .linked a.youtube {
  margin-left: 60px;
}
@media (max-width: 1600px) {
  .footer__content .linked a.youtube {
    margin-left: 50px;
  }
}
.footer__content .linked a.youtube svg {
  width: 50px;
  left: -56px;
  transform: translate(0, -50%);
}
@media (max-width: 1600px) {
  .footer__content .linked a.youtube svg {
    width: 40px;
    left: -47px;
  }
}
@media (max-width: 1600px) and (max-width: 1200px) {
  .footer__content .linked a.youtube {
    margin: 0;
  }
}
.footer__content .linked a.facebook {
  margin-left: 50px;
}
@media (max-width: 1600px) {
  .footer__content .linked a.facebook {
    margin-left: 42px;
  }
}
@media (max-width: 1200px) {
  .footer__content .linked a.facebook {
    margin: 0;
  }
}
.footer__content .linked a.facebook svg {
  width: 37px;
  left: -44px;
  transform: translate(0, -52%);
}
@media (max-width: 1600px) {
  .footer__content .linked a.facebook svg {
    width: 32px;
    left: -39px;
  }
}
.footer__content .linked a.insta {
  margin-left: 50px;
}
@media (max-width: 1600px) {
  .footer__content .linked a.insta {
    margin-left: 42px;
  }
}
@media (max-width: 1200px) {
  .footer__content .linked a.insta {
    margin: 0;
  }
}
.footer__content .linked a.insta svg {
  width: 37px;
  left: -42px;
  transform: translate(0, -52%);
}
@media (max-width: 1600px) {
  .footer__content .linked a.insta svg {
    width: 32px;
    left: -37px;
  }
}
@media (max-width: 1200px) {
  .footer__content .linked a.insta svg {
    margin-top: -2px;
  }
}
.footer__content .linked svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
body.hidden {
  overflow: hidden;
}
body.hidden .popup {
  display: block;
}
.popup.show_popup {
  visibility: visible;
  opacity: 1;
}
.popup {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0;
  display: none;
  visibility: hidden;
  transition: 0.5s;
}
.popup .close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .popup .close {
    top: 15px;
    right: 20px;
  }
}
@media (max-width: 480px) {
  .popup .close {
    width: 24px;
    height: 24px;
    right: 10px;
  }
}
.popup .close:hover:before,
.popup .close:hover:after {
  background: #7ed321;
}
.popup .close:before,
.popup .close:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 14px;
  left: 0;
  transition: background 0.3s;
}
@media (max-width: 480px) {
  .popup .close:before,
  .popup .close:after {
    top: 11px;
  }
}
.popup .close:before {
  transform: rotate(45deg);
}
.popup .close:after {
  transform: rotate(135deg);
}
.popup__content {
  position: absolute;
  top: 5vh;
  left: 12%;
  width: 76%;
  height: 90vh;
  max-width: 100%;
  min-height: 320px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 40px;
  border-radius: 10px;
  background: linear-gradient(180deg, #445c83 29.86%, #6082b8 101.48%);
  background-color: #445c83;
  font-size: 24px;
  line-height: 1.5;
/*+mw(800px)
			padding 25px 15px*/
}
@media (max-width: 1600px) {
  .popup__content {
    font-size: 22px;
  }
}
@media (max-width: 1300px) {
  .popup__content {
    left: 10%;
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .popup__content {
    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 30px);
    padding: 30px 20px;
  }
}
@media (max-width: 480px) {
  .popup__content {
    font-size: 20px;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100vh - 10px);
    padding: 25px 10px;
  }
}
.popup .double-line {
  left: 0;
  top: 0;
  transform: translate(-40%, -5%);
}
@media (max-width: 1500px) {
  .popup .double-line {
    width: 332px;
    height: 315px;
  }
}
@media (max-width: 1100px) {
  .popup .double-line {
    width: 232px;
    height: 215px;
  }
}
@media (max-width: 800px) {
  .popup .double-line {
    display: block;
    width: 222px;
    height: 205px;
    left: -5%;
  }
}
@media (max-width: 480px) {
  .popup .double-line {
    width: 152px;
    height: 135px;
  }
}
.popup .polygon {
  display: block;
  width: 42.5%;
  height: 0;
  padding-top: 48.90625%;
  pointer-events: none;
  position: absolute;
  left: 18%;
  top: 18.5%;
  transform-origin: 0 0;
  transform: scale(1.15);
}
.popup .polygon:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1100px) {
  .popup .polygon {
    top: 23%;
    left: 23%;
    transform: scale(1.5);
  }
}
@media (max-width: 800px) {
  .popup .polygon {
    top: 25%;
    left: 25%;
    transform: scale(1.6);
  }
}
@media (max-width: 480px) {
  .popup .polygon {
    top: 30%;
    transform: scale(1.75);
  }
}
.popup .polygon:before {
  transform: rotate(45deg);
}
.popup .bg-triangle {
  z-index: 1;
}
.popup__text {
  text-align: center;
/*font-size 24px
		line-height 1.5
		+mw(480px)
			font-size 22px*/
}
.popup__text .title {
  font-size: 72px;
  color: #7ed321;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (max-width: 1600px) {
  .popup__text .title {
    font-size: 60px;
  }
}
@media (max-width: 1100px) {
  .popup__text .title {
    font-size: 50px;
  }
}
@media (max-width: 800px) {
  .popup__text .title {
    font-size: 40px;
  }
}
@media (max-width: 480px) {
  .popup__text .title {
    font-size: 36px;
  }
}
.popup .green {
  color: #7ed321;
}
.popup__title {
  width: 50%;
  padding-top: 11vh;
  padding-left: 5%;
  padding-right: 8%;
}
@media (max-width: 1500px) {
  .popup__title {
    padding-left: 3.5%;
  }
  .popup__title .green {
    display: block;
  }
}
@media (max-width: 1100px) {
  .popup__title .text .green {
    display: inline;
  }
}
@media (max-width: 800px) {
  .popup__title {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 45px;
  }
  .popup__title .page-title {
    padding-top: 0;
  }
}
.popup__form {
  height: calc(100% - 30px);
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
/*@media (min-width:1241px)*/
  display: flex;
  padding: 10px 30px 0;
  margin-top: 30px;
}
.popup__form::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer;
  width: 3px;
}
.popup__form::-webkit-scrollbar-track {
  background-color: #6082b8;
  border: 0px;
}
.popup__form::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  cursor: pointer;
}
.popup__form::-webkit-scrollbar-track,
.popup__form::-webkit-scrollbar-thumb {
  margin-top: 10px;
}
@media (max-width: 800px) {
  .popup__form {
    flex-wrap: wrap;
    margin-top: 40px;
    height: calc(100% - 40px);
  }
  .popup__form::-webkit-scrollbar-track,
  .popup__form::-webkit-scrollbar-thumb {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .popup__form {
    margin-top: 40px;
    height: calc(100% - 40px);
    padding: 0 15px;
  }
}
.popup .page-title {
  width: 100%;
  margin-bottom: 1.2em;
  position: static;
}
.popup .page-title:before {
  display: none;
}
#formpopup .popup-triangle2,
#mrtPopup .popup-triangle2 {
  display: none;
}
#formpopup .popup__text,
#mrtPopup .popup__text {
  display: none;
}
@media (max-width: 800px) {
  #formpopup .popup-triangle1,
  #mrtPopup .popup-triangle1 {
    display: none;
  }
}
input::-webkit-input-placeholder {
  color: #fff;
  font-weight: 300;
}
input::-moz-placeholder {
  color: #fff;
  font-weight: 300;
}
input:-moz-placeholder {
  color: #fff;
  font-weight: 300;
}
input:-ms-input-placeholder {
  color: #fff;
  font-weight: 300;
}
textarea::-webkit-input-placeholder {
  color: #fff;
  font-weight: 300;
}
textarea::-moz-placeholder {
  color: #fff;
  font-weight: 300;
}
textarea:-moz-placeholder {
  color: #fff;
  font-weight: 300;
}
textarea:-ms-input-placeholder {
  color: #fff;
  font-weight: 300;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
}
input:focus::-moz-placeholder {
  opacity: 0;
}
input:focus::-moz-placeholder {
  opacity: 0;
}
input:focus::-ms-input-placeholder {
  opacity: 0;
}
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}
textarea:focus::-moz-placeholder {
  opacity: 0;
}
textarea:focus::-moz-placeholder {
  opacity: 0;
}
textarea:focus::-ms-input-placeholder {
  opacity: 0;
}
input:not([type="checkbox"]):not([type="radio"]),
textarea {
  display: block;
  width: 100%;
}
textarea {
  resize: none;
}
.contacts-form {
  width: 50%;
}
@media (max-width: 950px) {
  .contacts-form {
    width: 100%;
    margin-top: 80px;
  }
}
@media (max-width: 768px) {
  .contacts-form {
    margin-top: 40px;
  }
}
.send-form {
  width: 50%;
}
@media (max-width: 800px) {
  .send-form {
    width: 100%;
  }
}
.send-form .form-item {
  margin-bottom: 36px;
}
.send-form .form-item .description {
  background: #445c83;
}
.send-form .form-check {
  margin-bottom: 36px;
}
.contacts-form,
.send-form {
  position: relative;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
/*+mw(950px)
		width 100%
		margin-top 80px
	+mw(768px)
		margin-top 40px*/
}
@media (max-width: 480px) {
  .contacts-form,
  .send-form {
    font-size: 18px;
  }
}
.contacts-form .form-action-wrap,
.send-form .form-action-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts-form .captcha,
.send-form .captcha {
  width: 50%;
}
@media (max-width: 600px) {
  .contacts-form .captcha,
  .send-form .captcha {
    width: 100%;
  }
}
.contacts-form .form-action,
.send-form .form-action {
  width: 50%;
  position: relative;
  text-align: right;
}
.contacts-form .form-action .btn,
.send-form .form-action .btn {
  width: 250px;
  display: inline-flex;
  outline: none;
  border: none;
}
@media (max-width: 1600px) {
  .contacts-form .form-action .btn,
  .send-form .form-action .btn {
    width: 180px;
    height: 70px;
  }
}
.contacts-form .g-recaptcha,
.send-form .g-recaptcha {
  position: relative;
}
.contacts-form #error-text-captcha,
.send-form #error-text-captcha {
  font-size: 14px;
  color: #f00;
  line-height: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .contacts-form .form-action-wrap {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media (max-width: 950px) {
  .contacts-form .form-action-wrap {
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 800px) {
  .contacts-form .form-action-wrap {
    flex-direction: column;
  }
}
@media (max-width: 1600px) {
  .contacts-form .form-action {
    width: 100%;
    margin-top: 40px;
  }
}
@media (max-width: 950px) {
  .contacts-form .form-action {
    width: 50%;
    margin-top: 0;
  }
}
@media (max-width: 800px) {
  .contacts-form .form-action {
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
}
@media (max-width: 1800px) {
  .send-form .form-action-wrap {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media (max-width: 800px) {
  .send-form .form-action-wrap {
    align-items: center;
  }
}
@media (max-width: 1800px) {
  .send-form .form-action {
    width: 100%;
    margin-top: 40px;
  }
}
@media (max-width: 800px) {
  .send-form .form-action {
    text-align: center;
  }
}
.form-item {
  position: relative;
  margin-bottom: 40px;
}
.form-item input,
.form-item select,
.form-item textarea {
  font-family: 'ProximaNova', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
  color: #fff;
/*&:focus
			border-color $blue*/
}
.form-item input:focus + .description,
.form-item select:focus + .description,
.form-item textarea:focus + .description {
  opacity: 1;
}
.form-item input,
.form-item select {
  display: block;
  width: 100%;
  height: 65px;
  padding: 0 30px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}
@media (max-width: 480px) {
  .form-item input,
  .form-item select {
    padding: 0 15px;
  }
}
.form-item select {
  -ms-appearance: none;
  appearance: none;
}
.form-item textarea {
  display: block;
  width: 100%;
  height: 200px;
  padding: 18px 30px 17px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
/*@media (min-width:1241px)*/
}
.form-item textarea::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer;
  width: 3px;
}
.form-item textarea::-webkit-scrollbar-track {
  background-color: #6082b8;
  border: 0px;
}
.form-item textarea::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  cursor: pointer;
}
.form-item textarea::-webkit-scrollbar-track,
.form-item textarea::-webkit-scrollbar-thumb {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (max-width: 480px) {
  .form-item textarea {
    padding: 18px 15px 17px;
  }
}
.form-item .description {
  position: absolute;
  top: 0;
  left: 28px;
  transform: translateY(-50%);
  padding: 0 15px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  background: #384c6c;
  opacity: 0;
  transition: opacity 0.1s;
}
@media (max-width: 480px) {
  .form-item .description {
    left: 18px;
  }
}
.form-item .error-text {
  font-size: 14px;
  color: #f00;
  line-height: 20px;
  position: absolute;
  top: 102%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.form-item.active .description {
  opacity: 1;
}
.form-item.error .error-text,
.form-item.has-error .error-text {
  opacity: 1;
}
.form-item.error input,
.form-item.has-error input,
.form-item.error textarea,
.form-item.has-error textarea,
.form-item.error select,
.form-item.has-error select {
  border-color: #f00;
}
.form-item.error .select2-container--default .select2-selection--single,
.form-item.has-error .select2-container--default .select2-selection--single,
.form-item.error .select2-container--default .select2-selection--single .select2-selection__arrow b,
.form-item.has-error .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #f00;
}
.form-item.ok .description {
  opacity: 1;
}
.form-check {
  font-size: 16px;
  padding-left: 40px;
  margin-bottom: 40px;
  position: relative;
}
.form-check [type="checkbox"] {
  display: none;
}
.form-check.error label:before,
.form-check.has-error label:before {
  border-color: #f00;
}
.form-check label {
  position: relative;
}
.form-check label:before {
  content: '';
  display: block;
  width: 27px;
  height: 27px;
  border: 1px solid #fff;
  border-radius: 5px;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
.form-check label:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #7ed321;
  border-left: 2px solid #7ed321;
  transform: rotate(-45deg);
  margin-top: -7px;
  position: absolute;
  left: -32px;
  top: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}
.form-check [type="checkbox"]:checked + label:after {
  opacity: 1;
}
.form-check a {
  color: #7ed321;
  text-decoration-color: #64ac3a;
  transition: text-decoration-color 0.3s;
}
.form-check a:hover {
  text-decoration-color: transparent;
}
.select2-container {
  z-index: 1200;
}
.form-item .select2-container {
  z-index: 1;
}
.select2-container--default .select2-selection--single {
  border-radius: 5px;
  border: 1px solid #fff;
  background: transparent;
}
.select2-container .select2-selection--single {
  height: 65px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 18px 35px 17px 30px;
}
@media (max-width: 480px) {
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 18px 35px 17px 15px;
  }
}
.select2-selection:focus {
  outline: transparent;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 15px;
  right: 20px;
  top: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  width: 10px;
  height: 10px;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(135deg);
}
.select2-dropdown {
  border: 1px solid #fff;
  background-color: #384c6c;
  border-radius: 5px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
/*@media (min-width:1241px)*/
}
.select2-dropdown::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer;
  width: 3px;
}
.select2-dropdown::-webkit-scrollbar-track {
  background-color: #6082b8;
  border: 0px;
}
.select2-dropdown::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  cursor: pointer;
}
.select2-container--default .select2-results>.select2-results__options {
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
/*@media (min-width:1241px)*/
  max-height: 240px;
}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer;
  width: 3px;
}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track {
  background-color: #6082b8;
  border: 0px;
}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  cursor: pointer;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}
.select2-results__option {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  min-height: 60px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #6082b8;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #6082b8;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #fff;
}
@media (max-width: 960px) {
  .gallery3 .section-gallery {
    padding-bottom: 60px;
  }
}
.section-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 45px 0;
/*+mw(1600px)
		padding 20px 0*/
}
@media (max-width: 960px) {
  .section-gallery {
    padding: 20px 0 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.section-gallery__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  margin-right: 8%;
  flex-shrink: 0;
  font-size: 16px;
  color: #fff;
}
@media (max-width: 1200px) {
  .section-gallery__info {
    width: auto;
    max-width: 320px;
    margin-right: 7.5%;
  }
}
@media (max-width: 960px) {
  .section-gallery__info {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .section-gallery__info {
    width: 85%;
  }
}
.section-gallery__info.top {
  padding-top: 5%;
}
@media (max-width: 1200px) {
  .section-gallery__info.top {
    padding-top: 0;
  }
}
.section-gallery__title {
  margin: 0 0 30px 0;
  font-size: 50px;
  font-weight: 300;
}
@media (max-width: 1600px) {
  .section-gallery__title {
    font-size: 38px;
  }
}
@media (max-width: 960px) {
  .section-gallery__title {
    font-size: 28px;
  }
}
.section-gallery__text {
  line-height: 1.8;
}
.section-gallery .all-btn {
  position: absolute;
  right: auto;
  top: auto;
  left: 7.5%;
  bottom: 20vh;
}
@media (max-width: 1600px) {
  .section-gallery .all-btn {
    bottom: 18vh;
  }
}
@media (max-width: 1200px) {
  .section-gallery .all-btn {
    position: static;
    margin-top: 30px;
  }
}
@media (max-width: 960px) {
  .section-gallery .all-btn {
    position: absolute;
    bottom: 0;
    margin-top: 0;
  }
}
.section-gallery__slider {
  position: relative;
  width: 60%;
  height: 100%;
  padding-left: 110px;
}
@media (max-width: 1200px) {
  .section-gallery__slider {
    padding-left: 50px;
  }
}
@media (max-width: 960px) {
  .section-gallery__slider {
    width: 85%;
    padding-left: 0;
    padding-top: 30px;
  }
}
.gallery1 .section-gallery__info {
  font-size: 24px;
}
@media (max-width: 1600px) {
  .gallery1 .section-gallery__info {
    font-size: 20px;
  }
}
@media (max-width: 960px) {
  .gallery1 .section-gallery__info {
    font-size: 18px;
  }
}
.section-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 960px) {
  .section-slider {
    margin-bottom: 40px;
  }
}
.section-slider__item {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .section-slider__item {
    height: 70vh;
  }
}
@media (max-width: 960px) {
  .section-slider__item {
    height: 50vh;
  }
}
.section-slider__item .img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-slider__item .icon {
  position: absolute;
  bottom: 40px;
  right: 70px;
  width: 115px;
  z-index: 5;
}
@media (max-width: 960px) {
  .section-slider__item .icon {
    bottom: 10px;
    right: 10px;
  }
}
.section-slider__item .icon img {
  transition: transform 0.3s;
}
.section-slider__item .icon:hover img {
  transform: skew(15deg, 15deg);
}
.section-slider-pagination {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  counter-reset: numbers;
}
@media (max-width: 960px) {
  .section-slider-pagination {
    width: 100%;
    height: auto;
    top: auto;
    bottom: 0;
    flex-direction: row;
  }
}
.section-slider-pagination .swiper-pagination-bullet {
  position: relative;
  display: block;
  background: none;
  margin: 40px 0;
  color: #7ed321;
  font-family: 'ProximaNova', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  outline: none;
  opacity: 1;
}
@media (max-width: 1600px) {
  .section-slider-pagination .swiper-pagination-bullet {
    margin: 30px 0;
  }
}
@media (max-width: 960px) {
  .section-slider-pagination .swiper-pagination-bullet {
    margin: 0 4px;
    border: 2px solid #7ed321;
    background-color: transparent;
    opacity: 1;
    cursor: pointer;
    outline-color: transparent;
  }
}
.section-slider-pagination .swiper-pagination-bullet:hover:before {
  transform: scale(2);
}
.section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #fff;
}
@media (max-width: 960px) {
  .section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #7ed321;
  }
}
.section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  transform: scale(5) translate(-0.5em, 0.3em);
}
@media (max-width: 1600px) {
  .section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    transform: scale(4) translate(-0.5em, 0.3em);
  }
}
@media (max-width: 1200px) {
  .section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    transform: scale(3) translate(-0.5em, 0.3em);
  }
}
.section-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  transform: none;
}
.section-slider-pagination .swiper-pagination-bullet:before {
  content: '';
  display: block;
  counter-increment: numbers;
  content: counter(numbers, decimal-leading-zero) '';
  transition: transform 0.3s;
  transform-origin: right bottom;
}
@media (max-width: 960px) {
  .section-slider-pagination .swiper-pagination-bullet:before {
    display: none;
  }
}
.section-slider-pagination .swiper-pagination-bullet:after {
  content: '';
  position: absolute;
  top: 2em;
  left: -4em;
  width: 15em;
  height: 1px;
  background: #7ed321;
  transform: scale(0, 1);
  transition: transform 0.3s;
  transform-origin: left center;
}
@media (max-width: 1200px) {
  .section-slider-pagination .swiper-pagination-bullet:after {
    width: 10em;
  }
}
@media (max-width: 1600px) {
  .section-slider-pagination .swiper-pagination-bullet:after {
    left: -3em;
  }
}
@media (max-width: 960px) {
  .section-slider-pagination .swiper-pagination-bullet:after {
    display: none;
  }
}
.section-gallery.right-gallery {
  justify-content: flex-start;
}
@media (max-width: 960px) {
  .section-gallery.right-gallery {
    flex-direction: column-reverse;
    justify-content: center;
  }
}
.section-gallery.right-gallery .section-gallery__slider {
  padding-left: 0;
  padding-right: 110px;
}
@media (max-width: 960px) {
  .section-gallery.right-gallery .section-gallery__slider {
    padding-right: 0;
  }
}
.section-gallery.right-gallery .section-gallery__info {
  margin-right: 0;
  margin-left: 8%;
}
@media (max-width: 960px) {
  .section-gallery.right-gallery .section-gallery__info {
    margin-left: 0;
  }
}
.section-gallery.right-gallery .section-slider-pagination {
  left: auto;
  right: 0;
}
.section-gallery.right-gallery .swiper-pagination-bullet:after {
  left: auto;
  right: -1em;
  height: 1px;
  transform-origin: right center;
}
.gallery-title {
  position: absolute;
  font-size: 300px;
  color: #fff;
  display: block;
  z-index: -1;
  letter-spacing: -0.05em;
  opacity: 0.05;
  pointer-events: none;
  top: 10%;
  left: -1%;
}
@media (max-width: 1600px) {
  .gallery-title {
    font-size: 200px;
  }
}
@media (max-width: 1200px) {
  .gallery-title {
    display: none;
  }
}
.polygon1,
.polygon2,
.polygon3 {
  display: block;
  width: 42.5%;
  height: 0;
  padding-top: 48.90625%;
  pointer-events: none;
  position: absolute;
}
.polygon1:before,
.polygon2:before,
.polygon3:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.polygon1 {
  bottom: -12vh;
  left: 30%;
  transform: scale(0.99);
}
.polygon1:before {
  transform: rotate(15.53deg);
}
.polygon2 {
  bottom: -12vh;
  left: 40%;
  transform: scale(0.99);
}
.polygon2:before {
  transform: rotate(42deg);
}
.polygon3 {
  bottom: 11vh;
  left: 31%;
  transform: scale(0.99);
}
.polygon3:before {
  transform: rotate(-15.53deg);
}
.news-slider-wrapper {
  position: relative;
  top: 39vh;
  overflow: hidden;
  margin: 0 0 0 7.5%;
}
@media (max-width: 1200px) {
  .news-slider-wrapper {
    top: auto;
  }
}
@media (max-width: 960px) {
  .news-slider-wrapper {
    padding-top: 30px;
  }
}
.news-slider {
  position: relative;
  width: 110%;
  margin-right: -5%;
}
@media (max-width: 960px) {
  .news-slider {
    width: 150%;
    margin-right: -25%;
    margin-bottom: 60px;
  }
}
.news-slider__item {
  position: relative;
}
.news-slider__link {
  text-decoration: none;
  display: block;
}
.news-slider__link .img {
  position: relative;
  padding-bottom: 100%;
  filter: grayscale(100%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.news-slider__link .img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32,39,47,0.7);
  transition: opacity 0.5s;
}
.news-slider__link .img:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(to bottom, rgba(42,32,40,0) 0%, rgba(42,32,40,0.85) 100%);
  opacity: 0;
  transition: opacity 0.3s;
}
.news-slider__link:hover .img {
  filter: none;
}
.news-slider__link:hover .img:before {
  opacity: 0;
}
.news-slider__link:hover .img:after {
  opacity: 1;
}
.news-slider__text {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 30px 30px;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.25;
}
@media (max-width: 1600px) {
  .news-slider__text {
    font-size: 24px;
  }
}
@media (max-width: 1200px) {
  .news-slider__text {
    font-size: 20px;
  }
}
@media (max-width: 960px) {
  .news-slider__text {
    font-size: 18px;
    padding: 0 10px 10px;
  }
}
.news-slider__text .title {
  margin-bottom: 15px;
}
.news-slider__text .date {
  font-size: 12px;
  color: #c4c4c4;
}
.news-arr {
  cursor: pointer;
}
.news-arr.right {
  display: none;
}
.news-arr.left {
  width: 80px;
  height: 80px;
  border-top: 1px solid #7ed321;
  border-left: 1px solid #7ed321;
  transition: all 0.3s ease;
  position: absolute;
  right: 18%;
  top: 50%;
  margin-top: -40px;
  z-index: 10;
  transform: rotate(135deg);
}
@media (max-width: 1600px) {
  .news-arr.left {
    width: 60px;
    height: 60px;
  }
}
.news-arr.left:after {
  content: '';
  position: absolute;
  width: 65px;
  height: 65px;
  border-top: 1px solid #7ed321;
  border-left: 1px solid #7ed321;
  right: 7px;
  top: 7px;
  transition: all 0.3s ease;
}
@media (max-width: 1600px) {
  .news-arr.left:after {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1200px) {
  .news-arr.left:after {
    width: 25px;
    height: 25px;
    right: -1px;
    top: 5px;
  }
}
.news-arr.left:hover {
  border-color: #fff;
}
.news-arr.left:hover:after {
  border-color: #fff;
}
@media (max-width: 1200px) {
  .news-arr.left {
    display: none;
  }
}
.news-arr.left:before {
  width: 400px;
  height: 400px;
  content: '';
  position: absolute;
  left: -400%;
  top: -400%;
  transform: rotate(45deg);
  z-index: -1;
}
.all-btn {
  display: flex;
  align-items: center;
  color: #7ed321;
  text-decoration: none;
  font-size: 20px;
  line-height: 1.5;
  text-decoration: none;
  position: relative;
/*&:hover
		color #fff
		.icon
			fill #fff*/
  position: absolute;
  z-index: 20;
  top: 23%;
  right: 7.5%;
}
.all-btn:hover:before {
  background: #7ed321;
}
.all-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.all-btn .icon {
  margin-left: 20px;
  width: 27px;
  height: 20px;
  fill: #7ed321;
}
@media (max-width: 1200px) {
  .all-btn {
    top: 18%;
  }
}
@media (max-width: 960px) {
  .all-btn {
    top: auto;
    bottom: 0;
    right: auto;
    left: 7.5%;
  }
}
.bottom-block {
  position: relative;
  margin: 95px 7.5%;
}
@media (max-width: 800px) {
  .bottom-block {
    margin: 60px 7.5% 40px;
  }
}
@media (max-width: 480px) {
  .bottom-block {
    margin: 60px 0 40px;
  }
}
.bottom-block .address-block {
  margin: 0 7.5%;
  padding: 0 7.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 5px solid rgba(255,255,255,0.04);
}
@media (max-width: 1600px) {
  .bottom-block .address-block {
    padding: 0 5%;
  }
}
@media (max-width: 1200px) {
  .bottom-block .address-block {
    margin: 0 0.5%;
  }
}
@media (max-width: 768px) {
  .bottom-block .address-block {
    border: none;
    align-items: center;
    justify-content: center;
  }
}
.bottom-block .address-block__wrap {
  margin: 2.75em 0 1.75em;
}
@media (max-width: 1600px) {
  .bottom-block .address-block__wrap {
    margin: 2em 0 1em;
  }
}
@media (max-width: 768px) {
  .bottom-block .address-block__wrap {
    display: none;
  }
}
.bottom-block .line-left {
  position: absolute;
  left: -9.2%;
  top: -8%;
  display: block;
  width: 120px;
  height: 120px;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .bottom-block .line-left {
    width: 92px;
    height: 92px;
  }
}
.bottom-block .line-left:before {
  content: '';
  display: block;
  width: 170px;
  height: 1px;
  background: #7ed321;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 1600px) {
  .bottom-block .line-left:before {
    width: 130px;
  }
}
@media (max-width: 800px) {
  .bottom-block .line-left {
    display: none;
  }
}
.bottom-block .line-right {
  position: absolute;
  right: -10%;
  bottom: -40%;
  display: block;
  width: 120px;
  height: 120px;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .bottom-block .line-right {
    width: 92px;
    height: 92px;
  }
}
.bottom-block .line-right:before {
  content: '';
  display: block;
  width: 170px;
  height: 1px;
  background: #7ed321;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 1600px) {
  .bottom-block .line-right:before {
    width: 130px;
  }
}
@media (max-width: 800px) {
  .bottom-block .line-right {
    display: none;
  }
}
.address-block {
  font-size: 20px;
}
@media (max-width: 1600px) {
  .address-block {
    font-size: 18px;
  }
}
.address-block p {
  margin-bottom: 20px;
}
.address-block .tel,
.address-block .email,
.address-block .address {
  font-size: 30px;
  line-height: 1.3333;
}
@media (max-width: 1600px) {
  .address-block .tel,
  .address-block .email,
  .address-block .address {
    font-size: 24px;
  }
}
.address-block .tel,
.address-block .address-map {
  text-decoration: none;
  position: relative;
}
.address-block .tel:hover:before,
.address-block .address-map:hover:before {
  background: #fff;
}
.address-block .tel:before,
.address-block .address-map:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.address-block .email {
  color: #7ed321;
  text-decoration: none;
  position: relative;
}
.address-block .email:hover:before {
  background: #7ed321;
}
.address-block .email:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.address-block .title {
  margin-bottom: 7px;
}
.bg-triangle {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}
.bg-triangle svg {
  width: 100%;
  height: auto;
/*perspective 400px*/
  perspective: 800px;
  transform-style: preserve-3d;
}
.bg-triangle svg path {
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}
.bg-triangle .tr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.bg-triangle .tr1 {
  z-index: 1;
}
.bg-triangle .tr2 {
  z-index: 2;
}
.bg-triangle .tr3 {
  z-index: 3;
}
.bg-triangle1 {
  width: 27%;
  left: -8.3%;
  top: 17.5%;
}
.bg-triangle1 .path1-tr1 {
  animation: rotate-triangle1 20s linear infinite;
}
.bg-triangle1 .path2-tr1 {
  animation: rotate-triangle2 10s linear infinite;
}
.bg-triangle1 .path3-tr1 {
  animation: rotate-triangle3 30s linear infinite;
}
@media (max-width: 1100px) {
  .bg-triangle1 {
    top: 30%;
    left: -5%;
  }
}
@media (max-width: 480px) {
  .bg-triangle1 {
    display: none;
  }
}
.bg-triangle2 {
  width: 49.3%;
  right: -8.4%;
  top: -18.5%;
/*width 30.8%
	right 0.88%
	top 2.5%*/
}
.bg-triangle2 .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.bg-triangle2 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.bg-triangle2 .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
@media (max-width: 480px) {
  .bg-triangle2 {
    top: 0;
    right: -25%;
    width: 105%;
  }
}
.bg-triangle3 {
  width: 24.6%;
  left: 27.1%;
  bottom: -26%;
}
.bg-triangle3 .path1-tr3 {
  animation: rotate-triangle1 40s linear infinite;
}
.bg-triangle3 .path2-tr3 {
  animation: rotate-triangle2 30s linear infinite;
}
.bg-triangle3 .path3-tr3 {
  animation: rotate-triangle3 50s linear infinite;
}
.bg-triangle4 {
  width: 11%;
  right: 10.3%;
  bottom: 0;
}
.bg-triangle4 .path1-tr4 {
  animation: rotate-triangle3 50s linear infinite;
}
.bg-triangle4 .path2-tr4 {
  animation: rotate-triangle1 40s linear infinite;
}
@media (max-width: 800px) {
  .bg-triangle4 {
    width: 22%;
  }
}
@media (max-width: 480px) {
  .bg-triangle4 {
    width: 40%;
  }
}
.bg-triangle5 {
  width: 7.5%;
  left: 41.8%;
  top: 17%;
}
.bg-triangle5 svg {
  animation: rotate-triangle1 40s linear infinite;
}
.triangle1 {
  width: 30%;
  right: -2%;
  bottom: -10%;
/*width 30.8%
	right 0.88%
	top 2.5%*/
}
.triangle1 .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle1 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle1 .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
@media (max-width: 480px) {
  .triangle1 {
    top: 0;
    right: -25%;
    width: 105%;
  }
}
.triangle2 {
  width: 25%;
  left: -2%;
  bottom: -8%;
}
.triangle2 .path1-tr2 {
  animation: rotate-triangle3 30s linear infinite;
}
.triangle2 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle2 .path3-tr2 {
  animation: rotate-triangle1 40s linear infinite;
}
.triangle3 {
  width: 27%;
  right: -3%;
  top: 5%;
}
.triangle3 .path1-tr1 {
  animation: rotate-triangle1 20s linear infinite;
}
.triangle3 .path2-tr1 {
  animation: rotate-triangle2 10s linear infinite;
}
.triangle3 .path3-tr1 {
  animation: rotate-triangle3 30s linear infinite;
}
@media (max-width: 1100px) {
  .triangle3 {
    top: 30%;
    left: -5%;
  }
}
@media (max-width: 480px) {
  .triangle3 {
    display: none;
  }
}
.triangle4 {
  width: 27%;
  right: 3%;
  top: 36%;
}
.triangle4 .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle4 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle4 .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
@media (max-width: 1100px) {
  .triangle4 {
    right: 3%;
    top: 36%;
  }
}
@media (max-width: 480px) {
  .triangle4 {
    display: none;
  }
}
.triangle5 {
  width: 17%;
  left: 3%;
  top: 59%;
}
.triangle5 .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle5 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle5 .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
@media (max-width: 1100px) {
  .triangle5 {
    top: 60%;
    left: -5%;
  }
}
@media (max-width: 480px) {
  .triangle5 {
    display: none;
  }
}
.triangle6 {
  width: 10%;
  right: 3%;
  bottom: 10%;
  transform: rotate(45deg);
}
.triangle6 .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle6 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle6 .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
@media (max-width: 1100px) {
  .triangle6 {
    right: 3%;
    bottom: 10%;
  }
}
@media (max-width: 480px) {
  .triangle6 {
    display: none;
  }
}
.triangle-news {
  width: 30%;
  right: -5%;
  bottom: 17%;
}
.triangle-news .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle-news .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle-news .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
.triangle-contacts {
  width: 30%;
  left: -1%;
  bottom: 12%;
}
.triangle-contacts .path1-tr2 {
  animation: rotate-triangle1 30s linear infinite;
}
.triangle-contacts .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.triangle-contacts .path3-tr2 {
  animation: rotate-triangle3 40s linear infinite;
}
.popup-triangle1 {
  width: 45%;
  bottom: -16%;
  left: -2%;
}
.popup-triangle1 .path1-tr2 {
  animation: rotate-triangle3 30s linear infinite;
}
.popup-triangle1 .path2-tr2 {
  animation: rotate-triangle2 20s linear infinite;
}
.popup-triangle1 .path3-tr2 {
  animation: rotate-triangle1 40s linear infinite;
}
@media (max-width: 1100px) {
  .popup-triangle1 {
    bottom: -12%;
  }
}
@media (max-width: 800px) {
  .popup-triangle1 {
    width: 70%;
    left: -8%;
    bottom: -10%;
  }
}
@media (max-width: 480px) {
  .popup-triangle1 {
    width: 95%;
    left: -15%;
  }
}
.popup-triangle2 {
  width: 37%;
  right: -3%;
  top: 5%;
}
.popup-triangle2 .path1-tr1 {
  animation: rotate-triangle1 20s linear infinite;
}
.popup-triangle2 .path2-tr1 {
  animation: rotate-triangle2 10s linear infinite;
}
.popup-triangle2 .path3-tr1 {
  animation: rotate-triangle3 30s linear infinite;
}
@media (max-width: 480px) {
  .popup-triangle2 {
    width: 57%;
    right: -9%;
  }
}
@-moz-keyframes rotate-triangle1 {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 180deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@-webkit-keyframes rotate-triangle1 {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 180deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@-o-keyframes rotate-triangle1 {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 180deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@keyframes rotate-triangle1 {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 180deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
@-moz-keyframes rotate-triangle2 {
  0% {
    transform: rotate3d(1, 1, 1, 30deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 210deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 390deg);
  }
}
@-webkit-keyframes rotate-triangle2 {
  0% {
    transform: rotate3d(1, 1, 1, 30deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 210deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 390deg);
  }
}
@-o-keyframes rotate-triangle2 {
  0% {
    transform: rotate3d(1, 1, 1, 30deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 210deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 390deg);
  }
}
@keyframes rotate-triangle2 {
  0% {
    transform: rotate3d(1, 1, 1, 30deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 210deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 390deg);
  }
}
@-moz-keyframes rotate-triangle3 {
  0% {
    transform: rotate3d(-1, -1, -1, 180deg);
  }
  50% {
    transform: rotate3d(-1, -1, -1, 360deg);
  }
  100% {
    transform: rotate3d(-1, -1, -1, 540deg);
  }
}
@-webkit-keyframes rotate-triangle3 {
  0% {
    transform: rotate3d(-1, -1, -1, 180deg);
  }
  50% {
    transform: rotate3d(-1, -1, -1, 360deg);
  }
  100% {
    transform: rotate3d(-1, -1, -1, 540deg);
  }
}
@-o-keyframes rotate-triangle3 {
  0% {
    transform: rotate3d(-1, -1, -1, 180deg);
  }
  50% {
    transform: rotate3d(-1, -1, -1, 360deg);
  }
  100% {
    transform: rotate3d(-1, -1, -1, 540deg);
  }
}
@keyframes rotate-triangle3 {
  0% {
    transform: rotate3d(-1, -1, -1, 180deg);
  }
  50% {
    transform: rotate3d(-1, -1, -1, 360deg);
  }
  100% {
    transform: rotate3d(-1, -1, -1, 540deg);
  }
}
.pager {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pager ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pager .pager-item {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin: 0 10px;
}
@media (max-width: 480px) {
  .pager .pager-item {
    margin: 0 3px;
  }
}
.pager .pager-item a {
  display: block;
  text-decoration: none;
  transition: color 0.3s ease 0.15s, transform 0.15s ease;
}
.pager .pager-item.pager-current a,
.pager .pager-item a:hover {
  transform: scale(1.375);
  color: #7ed321;
}
.pager .pager-arr {
  width: 60px;
  height: 60px;
}
@media (max-width: 480px) {
  .pager .pager-arr {
    width: 50px;
    height: 50px;
  }
}
.pager .pager-arr a {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #7ed321;
  position: relative;
  transition: background 0.3s;
}
.pager .pager-arr a:hover {
  background: #7ed321;
}
.pager .pager-arr a:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -4px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.pager .pager-arr.pager-arr-next {
  margin-left: 10px;
}
@media (max-width: 480px) {
  .pager .pager-arr.pager-arr-next {
    margin-left: 5px;
  }
}
.pager .pager-arr.pager-arr-next a:before {
  transform: rotate(135deg);
  margin-left: -7px;
}
.pager .pager-arr.pager-arr-prev {
  margin-right: 10px;
}
@media (max-width: 480px) {
  .pager .pager-arr.pager-arr-prev {
    margin-right: 5px;
  }
}
.pager .pager-arr.pager-arr-prev a:before {
  transform: rotate(-45deg);
}
.page-main__item {
  overflow: hidden;
}
@media (max-width: 1200px) {
  .page-main__item .davids {
    display: none;
  }
  .page-main__item .polygon1 {
    display: none;
  }
}
.fp-viewing-0 .header .logo {
  display: none;
}
.hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 1200px) {
  .hero {
    min-height: 100vh;
  }
}
@media (max-width: 768px) {
  .hero {
    min-height: auto;
  }
}
.hero .corner-blue {
  width: 51.5%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1600px) {
  .hero .corner-blue {
    width: 49%;
  }
}
@media (max-width: 1300px) {
  .hero .corner-blue {
    width: 47%;
  }
}
@media (max-width: 800px) {
  .hero .corner-blue {
    width: 85%;
  }
}
.hero .corner-blue:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-bottom: 1250px solid transparent;
  border-left: 1250px solid #384c6c;
  position: absolute;
  top: 0;
  right: 0;
}
.hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 46px;
}
@media (max-width: 1600px) {
  .hero-content {
    padding-top: 26px;
  }
}
@media (max-width: 768px) {
  .hero-content {
    min-height: 50vh;
    display: block;
  }
}
.hero-content__left {
  width: 40%;
}
@media (max-width: 1200px) {
  .hero-content__left img {
    visibility: hidden;
  }
}
@media (max-width: 768px) {
  .hero-content__left {
    display: none;
  }
}
.hero-content__right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hero-content__right .btn {
  margin-left: 35px;
}
@media (max-width: 1600px) {
  .hero-content__right .btn {
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .hero-content__right .btn {
    display: none;
  }
}
.hero-content .hero-title {
  font-size: 30px;
  font-weight: 300;
  text-align: right;
  margin-bottom: 0;
}
@media (max-width: 1600px) {
  .hero-content .hero-title {
    font-size: 24px;
  }
}
@media (max-width: 1200px) {
  .hero-content .hero-title {
    display: none;
  }
}
.hero-content__bottom {
  display: none;
}
@media (max-width: 768px) {
  .hero-content__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero-content__bottom .hero-title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin-top: 60px;
  }
  .hero-content__bottom .btn {
    margin-top: 35px;
  }
}
.hero-content .hero-logo {
  width: 72.3%;
  margin-top: -10px;
  margin-bottom: 60px;
}
@media (max-width: 1600px) {
  .hero-content .hero-logo {
    margin-bottom: 50px;
  }
}
.hero-content__nav {
  color: #7ed321;
  font-size: 30px;
}
@media (max-width: 1600px) {
  .hero-content__nav {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .hero-content__nav {
    display: none;
  }
}
.hero-content__nav li a {
  text-decoration: none;
  position: relative;
}
.hero-content__nav li a:hover:before {
  background: #7ed321;
}
.hero-content__nav li a:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: transparent;
  opacity: 0.7;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background 0.3s;
}
.hero-image {
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 768px) {
  .hero-image {
    max-width: 120%;
    width: 120%;
    left: -5%;
  }
}
@media (max-width: 600px) {
  .hero-image {
    max-width: 140%;
    width: 140%;
    left: -12%;
  }
}
.davids {
  width: 32%;
  margin: 0 auto;
  padding-top: 90px;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
}
.david1 {
  width: 45%;
}
.david1 .david__text {
  padding-right: 40px;
}
.david1 .david__text .plus {
  right: 0;
}
.david1 .david__text .line {
  margin-left: 30px;
}
@media (max-width: 1600px) {
  .david1 .david__text .line {
    margin-left: 15px;
  }
}
.david2 {
  width: 40%;
}
.david2 .david__text {
  padding-left: 40px;
}
.david2 .david__text .plus {
  left: 0;
}
.david2 .david__text .line {
  margin-right: 30px;
}
@media (max-width: 1600px) {
  .david2 .david__text .line {
    margin-right: 15px;
  }
}
.david {
  position: relative;
  font-size: 24px;
}
@media (max-width: 1600px) {
  .david {
    font-size: 22px;
  }
}
.david__text {
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
/*&.text3
			top 15%
			left 50%
		&.text4
			top 30%
			left 68%
			// top 38%
			// left 61%
		&.text5
			top 45%
			left 45%
		&.text6
			top 85%
			right 60%
			// top 89%
			// left  33%*/
/*&.text5
			top 89%
			left  33%	
		&.text6   
			top 18%
			left 23%
		&.text6   
			top 18%
			left 23%
		&.text7
			top 44%
			left 44%*/
}
.david__text .title {
  display: block;
  text-decoration: none;
}
.david__text .title:hover {
  color: #7ed321;
}
.david__text .line {
  content: '';
  display: block;
  width: 199px;
  height: 1px;
  background: #7ed321;
}
@media (max-width: 1600px) {
  .david__text .line {
    width: 100px;
  }
}
.david__text .info {
  background-color: #7ed321;
  font-size: 16px;
  line-height: 30px;
  padding: 20px 25px;
  position: absolute;
  left: 22px;
  top: 0;
}
.david__text.text1 {
  top: 44%;
  right: 66%;
}
.david__text.text2 {
  top: 66%;
  right: 65%;
}
.david__text.text3 {
  top: 84%;
  right: 60%;
}
.david__text.text4 {
  top: 15%;
  left: 50%;
}
.david__text.text5 {
  top: 30%;
  left: 67%;
}
.david__text.text6 {
  top: 45%;
  left: 43%;
}
.david__text.text7 {
  top: 34%;
  right: 87%;
}
.david .plus {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  background: #7ed321;
  border: 8px solid #fff;
  border-radius: 50%;
  transition: border-color 0.3s;
}
.david .plus:hover {
  border-color: #7ed321;
}
.david .plus .icon {
  width: 14px;
  height: 14px;
  fill: #fff;
}
.davids-title {
  position: absolute;
  font-size: 300px;
  color: #fff;
  display: block;
  z-index: -1;
  letter-spacing: -0.05em;
  opacity: 0.05;
  pointer-events: none;
  top: 8vh;
  left: -1.65%;
}
@media (max-width: 1600px) {
  .davids-title {
    font-size: 200px;
  }
}
@media (max-width: 1200px) {
  .davids-title {
    display: none;
  }
}
.philosophy-block {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (max-width: 1200px) {
  .philosophy-block {
    height: auto;
  }
}
@media (max-width: 960px) {
  .philosophy-block {
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 960px) {
  .philosophy-block .btn {
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .philosophy-block .btn {
    margin: 0;
  }
}
.philosophy-block__img {
  width: 54%;
  align-self: flex-end;
  text-align: center;
  padding: 0 3.5%;
}
@media (max-width: 960px) {
  .philosophy-block__img {
    width: 85%;
    align-self: center;
    padding: 0 5%;
  }
}
@media (max-width: 480px) {
  .philosophy-block__img {
    padding: 0;
  }
}
.philosophy-block .doctors-bg {
  width: 54%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: 133% auto;
  background-position: 0% bottom;
}
@media (max-width: 960px) {
  .philosophy-block .doctors-bg {
    width: 85%;
    height: 0;
    padding-top: 58%;
    bottom: auto;
    top: -4%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media (max-width: 480px) {
  .philosophy-block .doctors-bg {
    top: 0;
  }
}
.philosophy-block__text {
  width: 38.5%;
  margin-right: 7.5%;
  padding-top: 15vh;
  padding-right: 8.5%;
  padding-left: 30px;
  font-size: 24px;
}
@media (max-width: 1600px) {
  .philosophy-block__text {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  .philosophy-block__text {
    padding-top: 0;
  }
}
@media (max-width: 960px) {
  .philosophy-block__text {
    width: 85%;
    margin-right: 0;
    padding: 35px 0 0;
    font-size: 18px;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .philosophy-block__text {
    text-align: left;
  }
}
.philosophy-block__text p {
  margin-bottom: 25px;
}
@media (max-width: 1600px) {
  .philosophy-block__text p {
    margin-bottom: 21px;
  }
}
.philosophy-block__text p:last-of-type {
  margin-bottom: 35px;
}
@media (max-width: 1600px) {
  .philosophy-block__text p:last-of-type {
    margin-bottom: 31px;
  }
}
.philosophy-block__text .thesis {
  font-size: 50px;
  color: #7ed321;
}
@media (max-width: 1600px) {
  .philosophy-block__text .thesis {
    font-size: 38px;
  }
}
@media (max-width: 1200px) {
  .philosophy-block__text .thesis {
    font-size: 30px;
  }
}
@media (max-width: 960px) {
  .philosophy-block__text .thesis {
    font-size: 24px;
  }
}
.philosophy-title {
  position: absolute;
  font-size: 300px;
  color: #fff;
  display: block;
  z-index: -1;
  letter-spacing: -0.05em;
  opacity: 0.05;
  pointer-events: none;
  top: 12%;
  right: -0.5%;
}
@media (max-width: 1600px) {
  .philosophy-title {
    font-size: 200px;
  }
}
@media (max-width: 1200px) {
  .philosophy-title {
    display: none;
  }
}
.line-philosophy1 {
  display: block;
  width: 120px;
  height: 120px;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
}
@media (max-width: 1600px) {
  .line-philosophy1 {
    width: 92px;
    height: 92px;
  }
}
.line-philosophy1:before {
  content: '';
  display: block;
  width: 170px;
  height: 1px;
  background: #7ed321;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 1600px) {
  .line-philosophy1:before {
    width: 130px;
  }
}
@media (max-width: 960px) {
  .line-philosophy1 {
    display: none;
  }
}
.line-philosophy1:before {
  width: 418%;
  left: -240%;
  bottom: -240%;
}
.line-philosophy2 {
  display: block;
  width: 120px;
  height: 120px;
  pointer-events: none;
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 30vh;
  z-index: -1;
}
@media (max-width: 1600px) {
  .line-philosophy2 {
    width: 92px;
    height: 92px;
  }
}
.line-philosophy2:before {
  content: '';
  display: block;
  width: 170px;
  height: 1px;
  background: #7ed321;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 1600px) {
  .line-philosophy2:before {
    width: 130px;
  }
}
@media (max-width: 960px) {
  .line-philosophy2 {
    display: none;
  }
}
.line-philosophy2:before {
  width: 356%;
  left: -100%;
  bottom: -100%;
}
.contacts-home {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .contacts-home {
    height: auto;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
.contacts-home .address-block {
  width: 38.5%;
  margin-left: 7.5%;
  padding: 0 7.5%;
/*+mw(768px)
			// padding 0 23px
			.btn
				width 100%*/
}
@media (max-width: 1200px) {
  .contacts-home .address-block {
    width: 100%;
    padding: 7.5%;
    margin-left: 0;
  }
}
.contacts-home .address-block__wrap {
  margin: 3.25em 0;
}
@media (max-width: 1600px) {
  .contacts-home .address-block__wrap {
    margin: 2em 0;
  }
}
@media (max-width: 1200px) {
  .contacts-home .address-block__wrap {
    margin-top: 0;
  }
}
@media (max-width: 960px) {
  .contacts-home .address-block__wrap {
    max-width: 256px;
  }
}
.map-home {
  width: 54%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1200px) {
  .map-home {
    width: 100%;
    margin-top: 45px;
    height: auto;
  }
}
.davids-block {
  padding-top: 12vh;
  padding-bottom: 7.5%;
  position: relative;
}
@media (max-width: 1600px) {
  .davids-block {
    padding-top: 0;
    padding-bottom: 5%;
  }
}
@media (max-width: 1200px) {
  .davids-block {
    display: none;
  }
}
.davids-block-mob {
  display: none;
}
@media (max-width: 1200px) {
  .davids-block-mob {
    display: block;
    padding-top: 30px;
    margin-bottom: 60px;
  }
  .davids-block-mob .swiper-wrapper {
    align-items: center;
    margin-bottom: 20px;
  }
  .davids-block-mob .swiper-slide {
    opacity: 0 !important;
  }
  .davids-block-mob .swiper-slide-active {
    opacity: 1 !important;
  }
  .davids-block-mob .item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .davids-block-mob .item__title {
    margin-top: 25px;
    text-decoration: none;
    text-align: center;
    padding: 0 15px;
  }
  .davids-block-mob .item img {
    max-height: 290px;
  }
  .davids-block-mob .item__img-outer {
    max-width: 290px;
    position: relative;
  }
  .davids-block-mob .david-1 .plus {
    top: 45%;
    left: 44%;
  }
  .davids-block-mob .david-2 .plus {
    top: 45%;
    left: 26%;
  }
  .davids-block-mob .david-3 .plus {
    top: 60%;
    left: 40%;
  }
  .davids-block-mob .david-4 .plus {
    top: 20%;
    left: 62%;
  }
  .davids-block-mob .david-5 .plus {
    top: 42%;
    left: 27%;
  }
  .davids-block-mob .david-6 .plus {
    top: 63%;
    left: 28%;
  }
  .davids-block-mob .david-7 .plus {
    top: 22%;
    left: 4%;
  }
  .davids-block-mob .pagination {
    text-align: center;
  }
  .davids-block-mob .pagination .swiper-pagination-bullet {
    border: 2px solid #7ed321;
    background-color: transparent;
    opacity: 1;
    cursor: pointer;
    outline-color: transparent;
  }
  .davids-block-mob .pagination .swiper-pagination-bullet-active {
    background-color: #7ed321;
  }
}
@media (max-width: 1200px) and (max-width: 800px) {
  .davids-block-mob {
    margin-bottom: 0;
  }
}
.davids-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .davids-list {
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .davids-list {
    margin-top: 60px;
  }
}
.davids-list__item {
  width: calc((100% - 2 * 60px) / 3);
  margin-bottom: 90px;
  font-size: 18px;
}
@media (max-width: 1600px) {
  .davids-list__item {
    width: calc((100% - 2 * 40px) / 3);
    margin-bottom: 60px;
  }
}
@media (max-width: 1200px) {
  .davids-list__item {
    width: 50%;
    padding: 0 25px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .davids-list__item {
    width: 100%;
    padding: 0;
  }
}
.davids-list__item .title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}
@media (max-width: 768px) {
  .davids-list__item .title {
    margin-bottom: 15px;
  }
}
.davids-list__item .title a {
  text-decoration: none;
  transition: color 0.3s;
}
.davids-list__item .title a:hover {
  color: #7ed321;
}
.davids-list__item .text {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .davids-list__item .text {
    margin-bottom: 15px;
  }
}
.davids-list__item .text p {
  margin-bottom: 0;
}
.polygon-david {
  display: block;
  width: 42.5%;
  height: 0;
  padding-top: 48.90625%;
  pointer-events: none;
  position: absolute;
  left: 25%;
  top: 25vh;
}
.polygon-david:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.polygon-david:before {
  transform: rotate(103.53deg);
}
.error-page .page-main__content {
  padding: 130px 0;
}
@media (max-width: 768px) {
  .error-page .page-main__content {
    padding: 130px 0 0;
  }
}
.page-404 {
  text-align: center;
}
.page-404__text {
  font-size: 400px;
  line-height: 0.8;
  color: #7ed321;
  position: relative;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  background: -webkit-linear-gradient(#7ed321, rgba(126,211,33,0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1200px) {
  .page-404__text {
    font-size: 200px;
  }
}
@media (max-width: 500px) {
  .page-404__text {
    font-size: 150px;
  }
}
@media (max-width: 900px) {
  .page-404 h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .page-404 h1 {
    font-size: 30px;
  }
}
.page-404__desk {
  font-size: 28px;
  font-weight: lighter;
}
@media (max-width: 500px) {
  .page-404__desk {
    font-size: 26px;
  }
}
.page-404__links {
  width: 750px;
  margin: 60px auto 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .page-404__links {
    width: 100%;
    padding: 0 50px;
  }
}
@media (max-width: 768px) {
  .page-404__links {
    display: block;
  }
}
.page-404__links a {
  text-transform: none;
  font-size: 32px;
}
.page-404__links a:before {
  background-color: #7ed321;
}
.page-404__links a:hover:before {
  background-color: transparent;
}
@media (max-width: 768px) {
  .page-404__links a {
    display: block;
    font-size: 26px;
  }
  .page-404__links a:before {
    background-color: transparent;
  }
  .page-404__links a:hover:before {
    background-color: transparent;
  }
}
@media screen and (max-height: 760px) and (min-width: 760px) {
  .page-404__text {
    font-size: 200px;
  }
  .page-404__desk {
    font-size: 22px;
  }
  .page-404__links {
    margin-top: 30px;
  }
}
.we-work .page-main__content {
  padding-top: 100px;
}
.works {
  padding-top: 20vh;
}
@media (max-width: 1200px) {
  .works {
    padding-top: 30px;
  }
}
.works .item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14%;
  margin-top: 90px;
}
@media (max-width: 1550px) {
  .works .item {
    padding-left: 8%;
  }
}
@media (max-width: 1200px) {
  .works .item {
    padding-left: 6%;
    margin-top: 60px;
  }
}
@media (max-width: 768px) {
  .works .item {
    padding: 0 30px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.works .item__text {
  width: 50%;
  padding-right: 17%;
}
@media (max-width: 1550px) {
  .works .item__text {
    padding-right: 8%;
  }
}
@media (max-width: 1200px) {
  .works .item__text {
    padding-right: 6%;
  }
}
@media (max-width: 768px) {
  .works .item__text {
    padding: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}
.works .item:first-child {
  margin-top: 0;
}
.works .item:nth-child(2n) {
  padding-left: 0;
  padding-right: 14%;
}
@media (max-width: 1550px) {
  .works .item:nth-child(2n) {
    padding-right: 8%;
  }
}
@media (max-width: 1200px) {
  .works .item:nth-child(2n) {
    padding-right: 6%;
  }
}
@media (max-width: 768px) {
  .works .item:nth-child(2n) {
    padding: 0 30px;
  }
}
.works .item:nth-child(2n) .item__text {
  padding-left: 17%;
  padding-right: 0;
}
@media (max-width: 1550px) {
  .works .item:nth-child(2n) .item__text {
    padding-left: 8%;
  }
}
@media (max-width: 1200px) {
  .works .item:nth-child(2n) .item__text {
    padding-left: 6%;
  }
}
@media (max-width: 768px) {
  .works .item:nth-child(2n) .item__text {
    padding: 0;
    order: 1;
  }
}
@media (max-width: 768px) {
  .works .item:nth-child(2n) img {
    order: 2;
  }
}
.works .item__title {
  font-size: 50px;
  margin-bottom: 35px;
}
@media (max-width: 1550px) {
  .works .item__title {
    font-size: 34px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1200px) {
  .works .item__title {
    font-size: 30px;
    line-height: 1.2;
  }
}
@media (max-width: 768px) {
  .works .item__title {
    line-height: 1;
  }
}
.works .item p {
  line-height: 30px;
  font-size: 18px;
}
@media (max-width: 1200px) {
  .works .item p {
    line-height: 1.2;
  }
}
.works .item img {
  max-height: 753px;
  width: 50%;
}
@media (max-width: 768px) {
  .works .item img {
    width: 100%;
    max-height: 350px;
    width: auto;
  }
}
.treating-part {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
}
@media (max-width: 1200px) {
  .treating-part {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-bottom: 30px;
  }
}
.treating-part__content {
  padding-top: 18vh;
  padding-left: 14%;
  padding-right: 15.2%;
  position: relative;
  width: calc(100% - 800px);
}
@media (max-width: 1680px) {
  .treating-part__content {
    padding-right: 4%;
    padding-top: 20vh;
  }
}
@media (max-width: 1480px) {
  .treating-part__content {
    width: calc(100% - 600px);
  }
}
@media (max-width: 1200px) {
  .treating-part__content {
    width: 100%;
    padding: 37px 113px 0;
  }
}
@media (max-width: 825px) {
  .treating-part__content {
    padding: 30px 30px 0;
  }
}
.treating-part__content p img,
.treating-part__content ul img,
.treating-part__content ol img,
.treating-part__content blockquote img,
.treating-part__content table img {
  margin-bottom: 0;
}
.treating-part__content h3,
.treating-part__content h4,
.treating-part__content h5,
.treating-part__content h6,
.treating-part__content ul,
.treating-part__content ol {
  margin-bottom: 21px;
  line-height: 30px;
}
@media (max-width: 768px) {
  .treating-part__content h3,
  .treating-part__content h4,
  .treating-part__content h5,
  .treating-part__content h6,
  .treating-part__content ul,
  .treating-part__content ol {
    line-height: 1.2;
  }
}
.treating-part__content blockquote,
.treating-part__content hr,
.treating-part__content table {
  margin: 50px 0;
}
@media (max-width: 1600px) {
  .treating-part__content blockquote,
  .treating-part__content hr,
  .treating-part__content table {
    margin: 30px 0;
  }
}
.treating-part__content blockquote {
  position: relative;
  padding-left: 50px;
}
.treating-part__content blockquote:before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background: #7ed321;
  position: absolute;
  left: 0;
  top: 0;
}
.treating-part__content hr {
  border-color: #7ed321;
}
.treating-part__content table {
  border: none;
  border-collapse: collapse;
}
.treating-part__content table td,
.treating-part__content table th {
  border: 1px solid #7ed321;
  padding: 20px;
}
@media (max-width: 768px) {
  .treating-part__content table td,
  .treating-part__content table th {
    padding: 10px;
  }
}
.treating-part__content table th {
  font-weight: normal;
}
.treating-part__content caption {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.treating-part__content h3 {
  font-size: 30px;
  font-weight: normal;
  color: #7ed321;
}
@media (max-width: 768px) {
  .treating-part__content h3 {
    font-size: 24px;
  }
}
.treating-part__content h4 {
  font-size: 24px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .treating-part__content h4 {
    font-size: 20px;
  }
}
.treating-part__content h5 {
  font-size: 20px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .treating-part__content h5 {
    font-size: 18px;
  }
}
.treating-part__content h6 {
  font-size: 18px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .treating-part__content h6 {
    font-size: 16px;
  }
}
.treating-part__content a {
  color: #7ed321;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s;
}
.treating-part__content a:hover {
  text-decoration-color: #99c36c;
}
.treating-part__content ol {
  padding: 0;
  counter-reset: numbers;
}
.treating-part__content ol li:before {
  counter-increment: numbers;
  content: counter(numbers) ". ";
  margin-right: 5px;
  color: #7ed321;
}
.treating-part__content ul li {
  padding-left: 50px;
  position: relative;
}
.treating-part__content ul li:before {
  content: '';
  position: absolute;
  top: 1em;
  left: 0;
  width: 35px;
  height: 1px;
  background: #7ed321;
}
@media (max-width: 768px) {
  .treating-part__content ul li:before {
    top: 0.6em;
  }
}
.treating-part__content strong {
  font-weight: bold;
}
.treating-part p {
  margin-bottom: 21px;
  line-height: 30px;
}
@media (max-width: 768px) {
  .treating-part p {
    line-height: 1.2;
  }
}
.treating-part .david {
  position: relative;
}
@media (max-width: 1200px) {
  .treating-part .david {
    padding: 0;
  }
}
.treating-part .david p {
  margin-bottom: 0;
  white-space: pre-wrap;
}
.treating-part .david img {
  width: 800px;
  height: auto;
}
@media (max-width: 1480px) {
  .treating-part .david img {
    width: 600px;
  }
}
@media (max-width: 1200px) {
  .treating-part .david img {
    width: auto;
    height: 600px;
  }
}
@media (max-width: 825px) {
  .treating-part .david img {
    height: 400px;
  }
}
@media (max-width: 825px) {
  .treating-part .david img {
    height: 290px;
  }
}
.treating-part .david__text.text-shoulder1 {
  top: 12%;
  left: 30%;
}
.treating-part .david__text.text-shoulder2 {
  top: 24%;
  left: 18%;
}
.treating-part .david__text.text-hip1 {
  top: 21%;
  right: 35%;
}
.treating-part .david__text.text-hip1 .info {
  left: auto;
  right: -22px;
}
.treating-part .david__text.text-hip2 {
  top: 43%;
  right: 17%;
}
.treating-part .david__text.text-hip2 .info {
  left: auto;
  right: -22px;
}
.treating-part .david__text.text-hip3 {
  top: 70%;
  right: 25%;
}
.treating-part .david__text.text-hip3 .info {
  left: auto;
  right: -22px;
}
.treating-part .david__text.text-pelvic1 {
  top: 65%;
  left: 47%;
}
.treating-part .david__text.text-knee1 {
  top: 47%;
  right: 33%;
}
.treating-part .david__text.text-knee1 .info {
  left: auto;
  right: -22px;
}
.treating-part .david__text.text-knee2 {
  top: 52%;
  left: 10%;
}
.treating-part .david__text.text-knee3 {
  top: 36%;
  left: 6%;
}
.treating-part .david__text.text-knee3 .info {
  top: auto;
  bottom: 0;
}
.treating-part .david__text.text-knee4 {
  top: 30%;
  right: 41%;
}
.treating-part .david__text.text-knee4 .info {
  left: auto;
  right: -22px;
  top: auto;
  bottom: 0;
}
.treating-part .david__text.text-foot1 {
  top: 26%;
  right: 50%;
}
.treating-part .david__text.text-foot1 .info {
  left: auto;
  right: -22px;
}
.treating-part .david__text.text-foot2 {
  top: 50%;
  right: 33%;
}
.treating-part .david__text.text-foot2 .info {
  left: auto;
  right: -22px;
  top: auto;
  bottom: 0;
}
.treating-part .david__text.text-foot3 {
  top: 56%;
  left: 45%;
}
.treating-part .david__text.text-spine1 {
  top: 22%;
  left: 26%;
}
.treating-part .david__text.text-spine2 {
  top: 52%;
  left: 34.5%;
}
.treating-part .david__text.text-hands1 {
  bottom: 15%;
  left: 32%;
}
.treating-part .david__text.text-hands2 {
  bottom: 25%;
  left: 47%;
}
.treating-part .david__text.text-hands3 {
  top: 91%;
  left: 15.5%;
}
.treating-part .david__text .plus {
  cursor: pointer;
}
.treating-part .david__text .plus svg {
  transition: transform 0.3s ease;
}
.treating-part .david__text .info {
  width: 335px;
}
@media (max-width: 1200px) {
  .treating-part .david__text .info {
    display: none;
  }
  .treating-part .david__text .plus:hover {
    border-color: #fff;
  }
}
.treating-part .david__text.active {
  z-index: 2;
}
.treating-part .david__text.active .info {
  opacity: 1;
}
@media (max-width: 1200px) {
  .treating-part .david__text.active {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #7ed321;
    z-index: 99;
    transition: all 0 ease;
  }
  .treating-part .david__text.active .info {
    transition: opacity 0 ease;
    display: block;
    padding: 35px;
    width: 100%;
    left: 0;
    top: 0;
  }
  .treating-part .david__text.active .plus {
    right: 0;
    top: 0;
    border-color: #7ed321;
  }
  .treating-part .david__text.active .plus:hover {
    border-color: #7ed321;
  }
  .treating-part .david__text.active .plus svg {
    transform: rotate(45deg) scale(1.3);
  }
}
.treating-part .david .info {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.arr {
  width: 80px;
  height: 80px;
  border-top: 1px solid #7ed321;
  border-left: 1px solid #7ed321;
  transition: all 0.3s ease;
  position: absolute;
  z-index: 30;
  left: 8%;
  top: 40vh;
}
@media (max-width: 1600px) {
  .arr {
    width: 60px;
    height: 60px;
  }
}
.arr:after {
  content: '';
  position: absolute;
  width: 65px;
  height: 65px;
  border-top: 1px solid #7ed321;
  border-left: 1px solid #7ed321;
  right: 7px;
  top: 7px;
  transition: all 0.3s ease;
}
@media (max-width: 1600px) {
  .arr:after {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1200px) {
  .arr:after {
    width: 25px;
    height: 25px;
    right: -1px;
    top: 5px;
  }
}
.arr:hover {
  border-color: #fff;
}
.arr:hover:after {
  border-color: #fff;
}
@media (max-width: 1200px) {
  .arr {
    display: none;
  }
}
.arr_l {
  transform: rotate(-45deg);
}
.arr_m {
  display: none;
}
@media (max-width: 1200px) {
  .arr_m {
    display: block;
    top: 100px;
    left: 8%;
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 1200px) {
  .arr:after {
    width: 25px;
    height: 25px;
    right: -1px;
    top: 5px;
  }
}
.doctor-info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 90px;
  position: relative;
}
@media (max-width: 1200px) {
  .doctor-info {
    padding-top: 30px;
  }
}
@media (max-width: 1080px) {
  .doctor-info {
    display: block;
  }
}
@media (max-width: 480px) {
  .doctor-info {
    padding-top: 0;
  }
}
.doctor-info .line-right {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 300px;
  height: 300px;
  pointer-events: none;
}
@media (max-width: 1080px) {
  .doctor-info .line-right {
    bottom: auto;
    top: 0;
  }
}
.doctor-info .line-right:before {
  content: '';
  display: block;
  width: 420px;
  height: 1px;
  background: #7ed321;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.doctor-info__img {
  width: 50%;
  padding-left: 8%;
  padding-right: 8%;
  position: relative;
}
@media (max-width: 1600px) {
  .doctor-info__img {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (max-width: 1400px) {
  .doctor-info__img {
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media (max-width: 1200px) {
  .doctor-info__img {
    width: 40%;
  }
}
@media (max-width: 1080px) {
  .doctor-info__img {
    width: 100%;
  }
}
.doctor-info__img:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 0 100%;
  left: 0;
  bottom: 124px;
  background-image: url("../img/lorem/Polygon.png");
  z-index: 21;
}
@media (max-width: 1080px) {
  .doctor-info__img:before {
    width: 540px;
    background-size: 161%;
    bottom: 95px;
    background-position: -87px 100%;
  }
}
@media (max-width: 800px) {
  .doctor-info__img:before {
    width: 485px;
    background-size: 161%;
    bottom: 70px;
  }
}
@media (max-width: 480px) {
  .doctor-info__img:before {
    width: 100%;
    bottom: 42px;
    background-position: -57px 100%;
  }
}
.doctor-info .white-triangle {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  text-align: left;
  width: 480px;
  height: 480px;
  margin: -40px 0 0 -40px;
  border-top-right-radius: 10%;
  -webkit-transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
  -ms-transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
}
.doctor-info .white-triangle:before,
.doctor-info .white-triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
  width: 100%;
  height: 100%;
  border-top-right-radius: 10%;
}
.doctor-info .white-triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
.doctor-info .white-triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
.doctor-info img {
  position: relative;
  z-index: 22;
}
@media (max-width: 1080px) {
  .doctor-info img {
    max-height: 500px;
  }
}
@media (max-width: 800px) {
  .doctor-info img {
    max-height: 420px;
  }
}
.doctor-info__text {
  width: 50%;
  padding-right: 8%;
}
@media (max-width: 1600px) {
  .doctor-info__text {
    padding-right: 6%;
  }
}
@media (max-width: 1400px) {
  .doctor-info__text {
    padding-right: 4%;
  }
}
@media (max-width: 1200px) {
  .doctor-info__text {
    width: 60%;
  }
}
@media (max-width: 1080px) {
  .doctor-info__text {
    width: 85%;
    margin: 60px auto 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .doctor-info__text {
    width: 100%;
    padding: 0 15px;
  }
}
.doctor-info__text h1 {
  font-size: 50px;
}
@media (max-width: 1600px) {
  .doctor-info__text h1 {
    font-size: 40px;
  }
}
@media (max-width: 480px) {
  .doctor-info__text h1 {
    font-size: 30px;
  }
}
.doctor-info__text p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 1em;
}
@media (max-width: 1600px) {
  .doctor-info__text p {
    font-size: 18px;
    line-height: 1.4;
  }
}
.doctor-info__text h3 {
  font-size: 30px;
  font-weight: normal;
  color: #7ed321;
}
@media (max-width: 768px) {
  .doctor-info__text h3 {
    font-size: 24px;
  }
}
.doctor-info__text blockquote {
  position: relative;
  padding-left: 50px;
  margin: 20px 0 0;
  text-align: right;
  font-style: italic;
}
.doctor-info__text blockquote:before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background: #7ed321;
  position: absolute;
  left: 0;
  top: 0;
}
.doctors-all {
  margin-top: 150px;
  position: relative;
}
@media (max-width: 1080px) {
  .doctors-all {
    margin-top: 60px;
  }
}
.doctors-all .triangle2 {
  bottom: 4%;
}
.doctors-all .line-right {
  position: absolute;
  left: -35px;
  top: 16px;
  display: block;
  width: 150px;
  height: 150px;
  pointer-events: none;
}
.doctors-all .line-right:before {
  content: '';
  display: block;
  width: 220px;
  height: 1px;
  background: #7ed321;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.doctors-all__inner {
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
  padding-top: 35px;
  margin-bottom: 80px;
  margin-right: -40px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .doctors-all__inner {
    padding-top: 0;
    margin-right: -25px;
    margin-bottom: 60px;
  }
}
.doctors-all .pager {
  font-size: 16px;
  margin-top: 85px;
}
@media (max-width: 1100px) {
  .doctors-all .pager {
    margin-top: 50px;
  }
}
.doctors-all .doctor {
  margin-top: 55px;
  width: 33.3%;
  text-align: left;
  display: inline-block;
  padding-right: 40px;
  text-decoration: none;
  transition: opacity 0.3s ease;
  vertical-align: top;
}
@media (max-width: 1100px) {
  .doctors-all .doctor {
    padding-right: 25px;
  }
}
@media (max-width: 768px) {
  .doctors-all .doctor {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .doctors-all .doctor {
    width: 100%;
    margin-top: 40px;
  }
}
.doctors-all .doctor:hover {
  opacity: 0.8;
}
.doctors-all .doctor:nth-child(3n) {
  position: relative;
}
.doctors-all .doctor:nth-child(3n):after {
  content: '';
  display: block;
  width: 700px;
  height: 700px;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  right: 79%;
  top: 35%;
  transform: rotate(19deg);
}
@media (max-width: 1400px) {
  .doctors-all .doctor:nth-child(3n):after {
    width: 400px;
    height: 400px;
  }
}
.doctors-all .doctor:nth-child(6n):after {
  content: none;
}
.doctors-all .doctor:nth-child(9n):after {
  transform: rotate(-25deg);
  bottom: 50px;
  top: auto;
}
.doctors-all .doctor__img {
  height: 700px;
  width: 100%;
  display: block;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1600px) {
  .doctors-all .doctor__img {
    height: 550px;
  }
}
@media (max-width: 1400px) {
  .doctors-all .doctor__img {
    height: 450px;
  }
}
@media (max-width: 1100px) {
  .doctors-all .doctor__img {
    height: 380px;
  }
}
.doctors-all .doctor__name {
  margin: 20px auto 0;
  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  text-align: left;
}
.doctors-all .doctor__position {
  font-size: 18px;
  margin-top: 15px;
  line-height: 1;
  display: inline-block;
}
.tabs-nav {
  overflow: visible;
}
.tabs-nav .btns {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible;
}
@media (max-width: 1200px) {
  .tabs-nav .btns {
    display: block;
    text-align: center;
    max-width: 400px;
    margin: auto;
  }
}
.tabs-nav .btns:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
}
@media (max-width: 1200px) {
  .tabs-nav .btns:after {
    content: none;
  }
}
.tabs-nav .content__item {
  display: none;
}
.tabs-nav .content__item.active {
  display: block;
}
.tabs-nav__btn {
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  padding-bottom: 19px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}
@media (max-width: 1200px) {
  .tabs-nav__btn {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.tabs-nav__btn:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  height: 4px;
  z-index: 3;
  background-color: transparent;
  transition: all 0.3s ease;
}
.tabs-nav__btn.active {
  color: #7ed321;
}
.tabs-nav__btn.active:after {
  background-color: #7ed321;
}
.tabs-nav__btn:hover {
  color: #7ed321;
}
.tabs-nav__btn:hover:after {
  background-color: #7ed321;
}
.news-block {
  padding-top: 190px;
  position: relative;
}
@media (max-width: 1600px) {
  .news-block {
    padding-top: 140px;
  }
}
@media (max-width: 1200px) {
  .news-block {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .news-block {
    padding-top: 20px;
  }
}
.news-block .arr.arr_l {
  top: 200px;
  left: 8.5%;
}
@media (max-width: 1600px) {
  .news-block .arr.arr_l {
    top: 150px;
  }
}
@media (max-width: 1200px) {
  .news-block .arr.arr_l {
    top: 50px;
  }
}
.news-block__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
}
@media (max-width: 1600px) {
  .news-block__list {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 768px) {
  .news-block__list {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.news-block__item {
  width: calc(100% / 3);
  padding: 0 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1600px) {
  .news-block__item {
    padding: 0 20px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1100px) {
  .news-block__item {
    width: calc(100% / 2);
  }
}
@media (max-width: 768px) {
  .news-block__item {
    padding: 0 15px;
  }
}
@media (max-width: 500px) {
  .news-block__item {
    width: 100%;
  }
}
.news-block__info {
  flex-grow: 1;
}
.news-block .title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
@media (max-width: 768px) {
  .news-block .title {
    margin-bottom: 15px;
  }
}
.news-block .title a {
  text-decoration: none;
  transition: color 0.3s;
}
.news-block .title a:hover {
  color: #7ed321;
}
.news-block .date {
  color: #c4c4c4;
}
.news-block .text {
  font-size: 18px;
  padding-top: 17px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .news-block .text {
    margin-bottom: 15px;
  }
}
.news-block .text p {
  margin-bottom: 0;
}
.news-block__img {
  display: block;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 18px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.news-block__img:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(32,39,47,0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: opacity 0.5s;
}
.news-block__img:hover:before {
  opacity: 0;
}
.news-block .polygon {
  display: block;
  width: 42.5%;
  height: 0;
  padding-top: 48.90625%;
  pointer-events: none;
  position: absolute;
  left: 18%;
  top: 26%;
}
.news-block .polygon:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.news-block .polygon:before {
  transform: rotate(20deg);
}
.news-text {
  padding: 0 20%;
  line-height: 30px;
  padding-bottom: 25px;
}
@media (max-width: 800px) {
  .news-text {
    padding: 0;
  }
}
.news-text img {
  margin-bottom: 55px;
}
@media (max-width: 1600px) {
  .news-text img {
    margin-bottom: 35px;
  }
}
.news-text p img,
.news-text ul img,
.news-text ol img,
.news-text blockquote img,
.news-text table img {
  margin-bottom: 0;
}
.news-text p,
.news-text h1,
.news-text h2,
.news-text h3,
.news-text h4,
.news-text h5,
.news-text h6,
.news-text ul,
.news-text ol {
  margin-bottom: 50px;
}
@media (max-width: 1600px) {
  .news-text p,
  .news-text h1,
  .news-text h2,
  .news-text h3,
  .news-text h4,
  .news-text h5,
  .news-text h6,
  .news-text ul,
  .news-text ol {
    margin-bottom: 30px;
  }
}
.news-text blockquote,
.news-text hr,
.news-text table {
  margin: 50px 0;
}
@media (max-width: 1600px) {
  .news-text blockquote,
  .news-text hr,
  .news-text table {
    margin: 30px 0;
  }
}
.news-text blockquote {
  position: relative;
  padding-left: 50px;
}
.news-text blockquote:before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background: #7ed321;
  position: absolute;
  left: 0;
  top: 0;
}
.news-text hr {
  border-color: #7ed321;
}
.news-text table {
  border: none;
  border-collapse: collapse;
}
.news-text table td,
.news-text table th {
  border: 1px solid #7ed321;
  padding: 20px;
}
@media (max-width: 768px) {
  .news-text table td,
  .news-text table th {
    padding: 10px;
  }
}
.news-text table th {
  font-weight: normal;
}
.news-text caption {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.news-text h1 {
  font-size: 50px;
  font-weight: normal;
}
@media (max-width: 1600px) {
  .news-text h1 {
    font-size: 45px;
  }
}
@media (max-width: 768px) {
  .news-text h1 {
    font-size: 30px;
  }
}
.news-text h2 {
  font-size: 40px;
  font-weight: normal;
}
@media (max-width: 1600px) {
  .news-text h2 {
    font-size: 38px;
  }
}
@media (max-width: 768px) {
  .news-text h2 {
    font-size: 28px;
  }
}
.news-text h3 {
  font-size: 30px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .news-text h3 {
    font-size: 24px;
  }
}
.news-text h4 {
  font-size: 24px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .news-text h4 {
    font-size: 20px;
  }
}
.news-text h5 {
  font-size: 20px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .news-text h5 {
    font-size: 18px;
  }
}
.news-text h6 {
  font-size: 18px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .news-text h6 {
    font-size: 16px;
  }
}
.news-text a {
  color: #7ed321;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s;
}
.news-text a:hover {
  text-decoration-color: #99c36c;
}
.news-text ol {
  padding: 0;
  counter-reset: numbers;
}
.news-text ol li:before {
  counter-increment: numbers;
  content: counter(numbers) ". ";
  margin-right: 5px;
  color: #7ed321;
}
.news-text ul li {
  padding-left: 50px;
  position: relative;
}
.news-text ul li:before {
  content: '';
  position: absolute;
  top: 1em;
  left: 0;
  width: 35px;
  height: 1px;
  background: #7ed321;
}
.news-text strong {
  font-weight: bold;
}
.contacts-block {
  padding-top: 190px;
}
@media (max-width: 1600px) {
  .contacts-block {
    padding-top: 140px;
  }
}
@media (max-width: 1200px) {
  .contacts-block {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .contacts-block {
    padding-top: 20px;
  }
}
.contacts-block__top,
.contacts-block__map {
  margin: 0 7.5%;
  padding-bottom: 80px;
}
@media (max-width: 1200px) {
  .contacts-block__top,
  .contacts-block__map {
    margin: 0;
  }
}
.contacts-block__map {
  margin-top: 70px;
}
@media (max-width: 1600px) {
  .contacts-block__map {
    margin-top: 40px;
  }
}
.contacts-block__map img {
  width: 100%;
}
.contacts-block__top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 950px) {
  .contacts-block__top {
    flex-wrap: wrap;
  }
}
.gallery__row {
  padding-top: 230px;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .gallery__row {
    padding-top: 30px;
  }
}
@media (max-width: 480px) {
  .gallery__row {
    display: block;
  }
}
.gallery__col {
  width: 48%;
  position: relative;
}
.gallery__col:last-child {
  margin-top: 200px;
}
@media (max-width: 1200px) {
  .gallery__col:last-child {
    margin-top: 150px;
  }
}
@media (max-width: 768px) {
  .gallery__col:last-child {
    margin-top: 95px;
  }
}
@media (max-width: 480px) {
  .gallery__col:last-child {
    margin-top: 0;
  }
}
.gallery__col:last-child .double-line {
  top: auto;
  left: auto;
  right: 60%;
  bottom: 0;
  z-index: 12;
}
@media (max-width: 1300px) {
  .gallery__col:last-child .double-line:before {
    content: none;
  }
}
.gallery__col:last-child .gallery__item:after {
  position: absolute;
  z-index: 9;
}
@media (max-width: 480px) {
  .gallery__col {
    width: 100%;
  }
}
.gallery__col:first-child .gallery__item:after {
  content: '';
  display: block;
  width: 800px;
  height: 800px;
  background-image: url("../img/svg/Polygon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  left: 57%;
  top: 13%;
  transform: rotate(19deg);
  z-index: -1;
}
@media (max-width: 1500px) {
  .gallery__col:first-child .gallery__item:after {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 1200px) {
  .gallery__col:first-child .gallery__item:after {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 800px) {
  .gallery__col:first-child .gallery__item:after {
    content: none;
  }
}
.gallery__col:first-child .gallery__item:nth-child(2n+1):after {
  transform: rotate(-75deg);
  bottom: 85px;
  left: 85%;
  top: auto;
}
@media (max-width: 800px) {
  .gallery__col:first-child .gallery__item:nth-child(2n+1):after {
    content: none;
  }
}
.gallery__col:first-child .gallery__item:nth-child(2n+1):before {
  content: '';
  top: 35%;
  right: -17%;
}
@media (max-width: 800px) {
  .gallery__col:first-child .gallery__item:nth-child(2n+1):before {
    content: none;
  }
}
.gallery__col:first-child .double-line {
  top: 11%;
  left: auto;
  right: -54%;
  z-index: 12;
}
@media (max-width: 1300px) {
  .gallery__col:first-child .double-line {
    top: 13%;
  }
  .gallery__col:first-child .double-line:before {
    width: 100%;
    left: 25%;
    top: 74%;
  }
  .gallery__col:first-child .double-line:after {
    width: 61.3%;
  }
}
.gallery__title {
  font-size: 50px;
  line-height: 45px;
  margin-bottom: 55px;
}
@media (max-width: 1440px) {
  .gallery__title {
    font-size: 40px;
    line-height: 1;
  }
}
@media (max-width: 1200px) {
  .gallery__title {
    font-size: 35px;
  }
}
@media (max-width: 768px) {
  .gallery__title {
    margin-bottom: 30px;
    font-size: 30px;
  }
}
.gallery__item {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 100px;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 11;
}
@media (max-width: 1440px) {
  .gallery__item {
    margin-bottom: 80px;
  }
}
@media (max-width: 1200px) {
  .gallery__item {
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .gallery__item {
    margin-bottom: 30px;
  }
}
.gallery__item:hover {
  opacity: 0.8;
}
.gallery__item:before {
  content: none;
  display: block;
  width: 200px;
  height: 1px;
  background: #7ed321;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
}
@media (max-width: 800px) {
  .gallery__item:before {
    content: none;
  }
}
.clinic {
  padding-top: 170px;
}
@media (max-width: 1200px) {
  .clinic {
    padding-top: 35px;
  }
}
.clinic__title {
  font-size: 50px;
  line-height: 45px;
  color: #7ed321;
}
.clinic__gallery {
  margin-top: 170px;
}
.clinic__inner {
  margin-top: 70px;
}
.clinic .bottom-block {
  margin-top: 170px;
}
.openNav .tour {
  z-index: 900;
}
.tour {
  position: relative;
  width: 100%;
  padding-top: 48%;
  z-index: 1001;
}
.tour iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.tour img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
/*margin auto
		width 100%
		height auto*/
}
.service-block {
  padding-top: 190px;
}
@media (max-width: 1600px) {
  .service-block {
    padding-top: 140px;
  }
}
@media (max-width: 1200px) {
  .service-block {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .service-block {
    padding-top: 20px;
  }
}
.service-block .triangle3 {
  right: auto;
  left: -7%;
  top: 12%;
}
.service-block .triangle2 {
  bottom: auto;
  top: 25%;
  left: auto;
  right: -3%;
}
@media (max-width: 480px) {
  .service-block .triangle2 {
    display: none;
  }
}
.service-block .triangle1 {
  bottom: auto;
  top: 40%;
  right: -4%;
}
@media (max-width: 480px) {
  .service-block .triangle1 {
    display: none;
  }
}
.mrt-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}
@media (max-width: 1200px) {
  .mrt-top {
    margin: 0;
    padding-bottom: 20px;
  }
}
@media (max-width: 800px) {
  .mrt-top {
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .mrt-top {
    padding: 0;
  }
}
.mrt-top__img {
  width: 53.125%;
  text-align: right;
}
@media (max-width: 800px) {
  .mrt-top__img {
    width: 100%;
    order: 1;
  }
}
@media (max-width: 480px) {
  .mrt-top__img {
    padding-left: 15px;
  }
}
.mrt-top__img img {
  max-width: 110%;
  width: 110%;
  margin-right: -10%;
  margin-top: calc(-190px - 10vh);
}
@media (max-width: 1600px) {
  .mrt-top__img img {
    margin-top: -140px;
  }
}
@media (max-width: 1200px) {
  .mrt-top__img img {
    margin-top: -40px;
  }
}
@media (max-width: 950px) {
  .mrt-top__img img {
    margin-top: 0;
  }
}
@media (max-width: 800px) {
  .mrt-top__img img {
    width: 85%;
    margin-top: -90px;
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .mrt-top__img img {
    margin-top: 0;
    width: auto;
  }
}
.mrt-top__text {
  width: 46.875%;
  padding-left: 13.8744%;
  line-height: 30px;
}
@media (max-width: 1200px) {
  .mrt-top__text {
    padding-left: 7.5%;
    padding-right: 10px;
  }
}
@media (max-width: 800px) {
  .mrt-top__text {
    width: 100%;
    order: 2;
    padding: 0 7.5%;
  }
}
@media (max-width: 768px) {
  .mrt-top__text {
    padding: 0 15px;
    text-align: justify;
  }
}
@media (max-width: 480px) {
  .mrt-top__text {
    text-align: left;
  }
}
.mrt-top__text p {
  margin-bottom: 21px;
}
.mrt-top .btn {
  margin-top: 58px;
}
@media (max-width: 800px) {
  .mrt-top .btn {
    margin: 40px auto 0;
  }
}
.mrt-content {
  margin: 0 40px;
  padding-bottom: 80px;
}
@media (max-width: 1200px) {
  .mrt-content {
    margin: 0;
    padding-bottom: 20px;
  }
}
.mrt-content h2 {
  font-size: 50px;
  font-weight: 300;
  color: #7ed321;
  margin-bottom: 50px;
  margin-top: 100px;
}
@media (max-width: 1600px) {
  .mrt-content h2 {
    font-size: 45px;
    margin-bottom: 35px;
    margin-top: 70px;
  }
}
@media (max-width: 1200px) {
  .mrt-content h2 {
    font-size: 28px;
  }
}
.mrt-content h4 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 50px;
}
@media (max-width: 1200px) {
  .mrt-content h4 {
    font-size: 20px;
  }
}
.mrt-content p {
  margin-bottom: 30px;
}
.mrt-content h4,
.mrt-content p {
  padding: 0 5%;
  line-height: 30px;
}
@media (max-width: 1200px) {
  .mrt-content h4,
  .mrt-content p {
    padding: 0;
  }
}
.mrt-content ul,
.mrt-content ol {
  padding: 10px 5%;
}
@media (max-width: 1200px) {
  .mrt-content ul,
  .mrt-content ol {
    padding: 0;
  }
}
.mrt-content li {
  line-height: 30px;
  margin-bottom: 16px;
}
.mrt-content li:last-child {
  margin-bottom: 0;
}
.mrt-content ul li {
  padding-left: 50px;
  position: relative;
}
.mrt-content ul li:before {
  content: '';
  position: absolute;
  top: 1em;
  left: 0;
  width: 35px;
  height: 1px;
  background: #7ed321;
}
.mrt-table-wrap {
  padding: 0 5%;
  margin-top: 150px;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .mrt-table-wrap {
    padding: 0;
    margin-top: 70px;
  }
}
@media (max-width: 800px) {
  .mrt-table-wrap {
    margin-top: 50px;
  }
}
.mrt-table-wrap table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
@media (max-width: 640px) {
  .mrt-table-wrap table {
    display: block;
  }
}
@media (max-width: 640px) {
  .mrt-table-wrap table tbody,
  .mrt-table-wrap table tr,
  .mrt-table-wrap table th,
  .mrt-table-wrap table td {
    display: block;
  }
}
.mrt-table-wrap table tr:nth-child(even) {
  background: rgba(255,255,255,0.06);
  background-blend-mode: soft-light;
}
.mrt-table-wrap table th {
  padding: 24px;
  font-size: 30px;
  font-weight: 300;
  color: #7ed321;
  line-height: 30px;
  text-align: left;
/*+mw(768px)
				font-size 24px
			+mw(480px)
				font-size 20px*/
}
@media (max-width: 1600px) {
  .mrt-table-wrap table th {
    font-size: 28px;
  }
}
@media (max-width: 1200px) {
  .mrt-table-wrap table th {
    font-size: 26px;
  }
}
@media (max-width: 640px) {
  .mrt-table-wrap table th:nth-child(2) {
    display: none;
  }
}
.mrt-table-wrap table td {
  font-size: 24px;
  padding: 17px 24px;
/*+mw(768px)
				padding 10px
				font-size 18px
			+mw(480px)
				font-size 16px*/
}
@media (max-width: 640px) {
  .mrt-table-wrap table td {
    padding: 15px 24px;
  }
}
@media (max-width: 640px) {
  .mrt-table-wrap table td:first-child {
    padding-top: 24px;
  }
}
.mrt-table-wrap table td:last-child {
  text-align: right;
}
@media (max-width: 640px) {
  .mrt-table-wrap table td:last-child {
    text-align: center;
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .mrt-table-wrap table td:last-child {
    padding: 14px 11px;
  }
}
.mrt-table-wrap table td:nth-child(2) {
  white-space: nowrap;
}
@media (max-width: 1600px) {
  .mrt-table-wrap table td {
    font-size: 22px;
  }
}
@media (max-width: 1200px) {
  .mrt-table-wrap table td {
    font-size: 20px;
  }
}
.order-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #7ed321;
  width: 177px;
  height: 46px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #7ed321;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}
@media (max-width: 640px) {
  .order-btn {
    width: 100%;
  }
}
.order-btn:hover {
  background: #7ed321;
  color: #fff;
}
.order-btn.disable,
.order-btn:disabled {
  background: rgba(126,211,33,0.4);
  cursor: default;
}
.mrt-slider-wrap {
  position: relative;
  margin-bottom: 100px;
}
@media (max-width: 1200px) {
  .mrt-slider-wrap {
    margin-bottom: 80px;
  }
}
@media (max-width: 800px) {
  .mrt-slider-wrap {
    margin-bottom: 70px;
  }
}
.mrt-slider-tabs {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 47%;
}
@media (max-width: 1100px) {
  .mrt-slider-tabs {
    margin-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .mrt-slider-tabs {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.mrt-slider-tabs__item {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  border-bottom: none;
  transition: 0.3s;
}
.mrt-slider-tabs__item.current {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.mrt-slider-tabs .image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: none;
}
.mrt-slider-thumbs-wrap {
  position: relative;
}
@media (max-width: 800px) {
  .mrt-slider-thumbs-wrap {
    padding-bottom: 40px;
  }
}
.mrt-slider-thumbs-wrap .arr {
  top: 50%;
  cursor: pointer;
}
.mrt-slider-thumbs-wrap .arr.arr-next {
  right: 1.5%;
  left: auto;
  transform: translateY(-50%) rotate(135deg);
}
.mrt-slider-thumbs-wrap .arr.arr-prev {
  left: 1.5%;
  transform: translateY(-50%) rotate(-45deg);
}
.mrt-slider-thumbs-wrap .arr.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: default;
}
.mrt-slider-thumbs-wrap .pagination {
  display: none;
  text-align: center;
}
@media (max-width: 800px) {
  .mrt-slider-thumbs-wrap .pagination {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.mrt-slider-thumbs-wrap .pagination .swiper-pagination-bullet {
  border: 2px solid #7ed321;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  outline: none;
  margin: 0 4px;
}
.mrt-slider-thumbs-wrap .pagination .swiper-pagination-bullet-active {
  background-color: #7ed321;
}
.mrt-slider-thumbs {
  position: relative;
  overflow: hidden;
}
.mrt-slider-thumbs__item {
  position: relative;
  cursor: pointer;
}
.mrt-slider-thumbs__item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: #20272f;
  opacity: 0.42;
  transition: opacity 0.5s;
}
@media (max-width: 800px) {
  .mrt-slider-thumbs__item:before {
    display: none;
  }
}
.mrt-slider-thumbs__item.current:before {
  opacity: 0;
}
.mrt-slider-thumbs__item:hover:not(.current):before {
  opacity: 0;
}
.mrt-slider-thumbs .image {
  position: relative;
  padding-bottom: 53.5%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .mrt-slider-thumbs .image {
    padding-bottom: 60%;
  }
}
@-moz-keyframes btn-blink {
  0% {
    background: #7ed321;
  }
  40% {
    background: #7ed321;
  }
  45% {
    background: #fff;
  }
  50% {
    background: #7ed321;
  }
  55% {
    background: #fff;
  }
  60% {
    background: #7ed321;
  }
  100% {
    background: #7ed321;
  }
}
@-webkit-keyframes btn-blink {
  0% {
    background: #7ed321;
  }
  40% {
    background: #7ed321;
  }
  45% {
    background: #fff;
  }
  50% {
    background: #7ed321;
  }
  55% {
    background: #fff;
  }
  60% {
    background: #7ed321;
  }
  100% {
    background: #7ed321;
  }
}
@-o-keyframes btn-blink {
  0% {
    background: #7ed321;
  }
  40% {
    background: #7ed321;
  }
  45% {
    background: #fff;
  }
  50% {
    background: #7ed321;
  }
  55% {
    background: #fff;
  }
  60% {
    background: #7ed321;
  }
  100% {
    background: #7ed321;
  }
}
@keyframes btn-blink {
  0% {
    background: #7ed321;
  }
  40% {
    background: #7ed321;
  }
  45% {
    background: #fff;
  }
  50% {
    background: #7ed321;
  }
  55% {
    background: #fff;
  }
  60% {
    background: #7ed321;
  }
  100% {
    background: #7ed321;
  }
}
