.news-block
	// padding-top 20vh
	padding-top 190px
	position relative
	+mw(1600px)
		padding-top 140px
	+mw(1200px)
		padding-top 40px
	+mw(768px)
		padding-top 20px
	.arr.arr_l
		top 200px
		left 8.5%
		+mw(1600px)
			top 150px
		+mw(1200px)
			top 50px
	&__list
		display flex
		flex-wrap wrap
		margin-left -30px
		margin-right -30px
		+mw(1600px)
			margin-left -20px
			margin-right -20px
		+mw(768px)
			margin-left -15px
			margin-right -15px
	&__item
		width calc(100%/3)
		padding 0 30px
		margin-bottom 60px
		display flex
		flex-direction column
		justify-content space-between
		align-items flex-start
		+mw(1600px)
			padding 0 20px
			margin-bottom 40px
		+mw(1100px)
			width calc(100%/2)
		+mw(768px)
			padding 0 15px
		+mw(500px)
			width 100%
	&__info
		flex-grow 1
	.title
		font-size 24px
		font-weight 300
		margin-bottom 10px
		display block
		cursor pointer
		+mw(768px)
			margin-bottom 15px
		a
			text-decoration none
			transition color .3s
			&:hover
				color $color2
				// hover()
	.date
		color #C4C4C4
	.text
		font-size 18px
		padding-top 17px
		margin-bottom 20px
		+mw(768px)
			margin-bottom 15px
		p
			margin-bottom 0
	&__img
		display block
		width 100%
		padding-top 100%
		margin-bottom 18px
		bg()
		position relative
		&:before
			content ''
			display block
			width 100%
			height 100%
			background rgba(32, 39, 47, 0.7)
			position absolute
			left 0
			top 0
			z-index 1
			transition opacity .5s
		&:hover:before
			opacity 0
	.polygon
		polygon()
		position absolute
		left 18%
		top 26%
		&:before
			transform rotate(20deg)
			